import map from 'lodash-es/map'
import React, { useEffect, useRef, useState } from 'react'
import { a } from 'react-spring/three'
import { Canvas, extend, useFrame, useThree } from 'react-three-fiber'
import { TextureLoader } from 'three'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import useStore from '../utils/useStore.js'
import Effects from './effects'
import Ball from './gl/Ball'
import Plane from './gl/Plane'

// import GLImage from './glimage'

extend({
  EffectComposer,
  ShaderPass,
  RenderPass,
  UnrealBloomPass
})

const Cam = ({ spring }) => {
  const camera = useRef()
  const { setDefaultCamera, size } = useThree()

  /**
   * LEAVE IT HERE UNTIL END
   * WHY THE HELL SPRING.SCROLLYVALUE.INTERPOLATE DOESN'T WORK?
   */
  // const documentHeight = useStore('documentHeight')

  // const bounds = [0,documentHeight]
  // const props = {domTarget: window}
  // const [{y}, set] = useSpring(() => ({y: 0}))

  // const fn = useCallback(
  //   ({xy: [, cy], previous: [, py], memo = y.getValue()}) => {
  //     const newY = clamp(memo + cy - py, ...bounds)
  //     set({y: newY})
  //     return newY
  //   },
  //   [bounds, y, set]
  // )

  // const bind = useWheel(fn, props)

  // useEffect(() => props && props.domTarget && bind(), [props, bind])

  useEffect(() => {
    setDefaultCamera(camera.current)
  }, [])

  useFrame(() => {
    camera.current.position.y = -spring.scrollYValue.getValue()
  })

  return (
    <a.orthographicCamera
      ref={camera}
      left={size.width / -2}
      right={size.width / 2}
      top={size.height / 2}
      bottom={size.height / -2}
      near={-150}
      far={150}
    // position-y={spring.scrollYValue.interpolate(v => -v)}
    />)
}

const FXLayer = ({ spring }) => {
  const [images, setImages] = useState([])
  const loaded = useStore('loaded')
  const setLoaded = useStore('setLoaded')
  const els = useRef()

  // useEffect(() => {
  //   setMobile(mobile)
  // }, [])

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     els.current = document.querySelectorAll('.glitch img')
  //     // console.log('IMAGES', els.current)
  //     const arr = mapPositions().map((image, i) => {
  //       const img = new Promise((resolve) => {
  //         new TextureLoader().load(image.currentSrc || image.src, resolve)
  //       }).then((img) => {
  //         return (<Plane
  //           key={`${image}${i}`}
  //           scale={image.scale}
  //           position={image.position}
  //           tex={img} />)
  //       })
  //       return img
  //     })

  //     Promise.all(arr).then((result) => {
  //       setImages(result)
  //       if (window !== undefined) window.addEventListener('resize', refreshPositions)
  //       setLoaded(true) // or whatever
  //     })
  //   }, 1000)
  //   return () => {
  //     clearTimeout(timer)
  //   }
  // }, [])

  // function mapPositions() {
  //   const arr = map(els.current, (el, i) => ({
  //     position: [((el.x + (el.width / 2)) - (window.innerWidth / 2)), -(el.y - window.innerHeight * 0.5 + el.height * 0.5)],
  //     scale: [el.width, el.height],
  //     src: el.currentSrc || el.src
  //   }))
  //   return arr
  // }
  // function refreshPositions() {
  //   const imgs = mapPositions().map((image, i) => (
  //     <Plane
  //       key={`${image}${i}`}
  //       scale={image.scale}
  //       position={image.position}
  //       src={image.src} />)
  //   )
  //   setImages(imgs)
  // }

  return (
    <>
      {/* <div className={`img-loader ${loaded ? 'loaded' : undefined}`}>
        <div className="outer">
          <div className="inner"></div>
        </div>
      </div> */}

      <div className={`canvas-wrapper ${loaded && 'loaded'}`}>
        <Canvas>
          <Cam spring={spring} />
          {/* {images} */}
          <Ball />
          <Effects />
        </Canvas>
      </div>

    </>

  )
}

export default FXLayer
