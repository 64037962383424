import { Vector2 } from 'three'

export default {
  uniforms: {
    amt: {
      type: 'v2',
      value: new Vector2(0.2, 0.1)
    },
    pixels: {
      type: 'v2',
      value: new Vector2(512, 512)
    },
    tDiffuse: {
      type: 't',
      value: null
    },
    time: {
      value: 0
    },
    resolution: {
      value: null
    },
    uVelo: {
      value: null
    },
    uMouse: {
      type: 'v2'
    }
  },
  vertexShader: `varying vec2 vUv;
  
  const float pi=3.1415925;
  void main(){
    vUv=uv;
    gl_Position=projectionMatrix*modelViewMatrix*vec4(position,1.);
  }`,
  fragmentShader: `precision mediump float;
  //setup for 2 texture
  varying vec2 vUv;
  uniform vec2 amt;
  uniform vec2 pixels;
  uniform sampler2D tDiffuse;
  uniform sampler2D tex1;
  
  uniform float time;
  uniform vec2 resolution;
  uniform vec2 uMouse;
  uniform float uVelo;
  
  float circle(vec2 uv,vec2 disc_center,float disc_radius,float border_size){
    uv-=disc_center;
    uv*=resolution;
    float dist=sqrt(dot(uv,uv));
    return smoothstep(disc_radius+border_size,disc_radius-border_size,dist);
  }
  
  float map(float value,float min1,float max1,float min2,float max2){
    return min2+(value-min1)*(max2-min2)/(max1-min1);
  }
  
  void main(){
    vec2 newUV = vUv;
    //output texture
    float newSpeed = uVelo;
    newSpeed -= 0.001;
    float mapSpeed = min(newSpeed, 0.1);
    newUV = -1. + 2. * newUV;
    // newUV -= uMouse;
    // newUV.x *= resolution.x;
    vec4 natural = texture2D(tDiffuse, vUv);
    vec4 color = vec4(0.,0.,0.,1.);
    vec2 pix = pixels*0.5;
		vec2 pcord = floor(vUv*pixels)/pixels;
    vec4 look = texture2D(tDiffuse,pcord);//sample repos texture
      //vec4 look = texture2D(tex1,vUv);//sample repos texture
    vec2 offs = (look.yw-look.xz)* 4.*(mapSpeed * uMouse*2.);
    vec2 coord = offs+vUv;//relative coordinates
    vec4 repos = texture2D(tDiffuse,coord);

    
    
        
    // vec4 finalImage = mix(repos, tDiffuse, c);
    // vec4 finalImage = vec4(vec3(circle(gl_PointCoord.xy/resolution, uMouse,0.,.2 )),1.0);
    float c = circle(newUV, uMouse, 0.4, 0.25)*1.25;
    float newCircle = c;
    newCircle *= c;
    float circle = dot( newCircle, c);
    // color = vec4(mix(color.r+repos.r, color.g+repos.g, color.b+repos.b, 1.);
    // color = color*vec4(vec3(c),1.);
    color = mix(repos, natural,1.- newCircle);
    gl_FragColor= color;
    // gl_FragColor = vec4(vec3(circle), 1.0);
    // gl_FragColor = vec4(uMouse.x, uMouse.y, 0.0, 1.0);
  }`
}
