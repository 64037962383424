import { gsap } from 'gsap'
import clamp from 'lodash-es/clamp'
import debounce from 'lodash-es/debounce'
import throttle from 'lodash-es/throttle'
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { animated as a, useSpring } from 'react-spring'
import { useGesture } from 'react-use-gesture'
import Credits from '../components/credits'
import CustomWaypoint from '../components/CustomWaypoint'
import Dot from '../components/dot'
import Form from '../components/form'
import FXLayer from '../components/fx'
import Image from '../components/image'
import useDeviceDetect from '../components/isMobile'
import Layout from '../components/layout'
import Nav from '../components/nav'
import Project from '../components/project'
import Scrollbar from '../components/scrollbar'
import SEO from '../components/seo'
import { QUESTIONS } from '../data/data'
import useStore from '../utils/useStore.js'

// import { Scrollama, Step } from 'react-scrollama'
function IndexPage() {
  /**
   * SCROLL LOGIC
   *
  */
  const scrollRef = useRef()
  const [height, setHeight] = useState(0)
  const setDocumentHeight = useStore('setDocumentHeight')
  const setStoreSpring = useStore('setStoreSpring')
  const loaded = useStore('loaded')
  const boundsRef = useRef()

  const { isMobile, isMobileOnly, isTablet } = useDeviceDetect()

  // handle the max-height of the scroll + reset on resize if the user is over the max
  const handleHeight = () => {
    const h = document.getElementById('___gatsby').offsetHeight
    setHeight(h)
    setDocumentHeight(h)
    return h
  }

  useLayoutEffect(() => {
    if (!isMobile) {
      handleHeight()
      // gestures
      boundsRef.current = [0, height - window.innerHeight]
      // console.log('HEIGHT', height)
    }
  })

  const archiveVideo = '/videos/banner_access_m.mp4'
  const rewardVideo = '/videos/banner_retrieve_m.mp4'

  const getVideoSrc = (section) => {
    if (typeof window !== 'undefined') {
      const width = window.innerWidth
      const videos = {
        mobile: {
          archive: 'videos/banner_access_m.mp4',
          reward: 'videos/banner_retrieve_m.mp4'
        },
        desktop: {
          archive: 'videos/retrieve.mp4',
          reward: 'videos/reward.mp4'
        }
      }
      if (width >= 768) return videos.desktop[section]
      return videos.mobile[section]
    }
  }

  useEffect(() => {
    // console.log('EFFECT', isMobile, isMobileOnly, isTablet)
  }, [isMobile, isMobileOnly, isTablet])

  useEffect(() => {
    // console.log('device detect', isMobile, isMobileOnly, isTablet)
    window.addEventListener('hashchange', e => {
      console.log(e)
      e.preventDefault()
      e.stopImmediatePropagation()
    })
    if (!isMobile) {
      const cb = () => {
        const h = handleHeight()
        if (spring.scrollYValue.getValue() > h) {
          setScrollYValue({
            scrollYValue: h
          })
        }
      }
      const onResize = debounce(cb, 100)
      window && window.addEventListener('resize', onResize)
      return () => {
        window && window.removeEventListener('resize', onResize)
      }
    }
  }, [])

  // set scroll value in the store
  const setScrollValue = useStore('setScrollValue')
  const throttledSetScroll = useMemo(() => throttle(setScrollValue, 500), [])

  // const props = {domTarget: window}
  const [spring, setScrollYValue] = useSpring(() => ({
    scrollYValue: 0,
    onFrame: ({ scrollYValue }) => {
      throttledSetScroll(scrollYValue)
    }
  }))

  const onWheel = useCallback(
    ({ xy: [, cy], previous: [, py], memo = spring.scrollYValue.getValue() }) => {
      const newY = clamp(memo + cy - py, ...boundsRef.current)
      setScrollYValue({ scrollYValue: newY })
      return newY
    },
    [boundsRef.current, spring.scrollYValue, setScrollYValue]
  )

  const bind = useGesture(
    {
      onWheel,
      onDrag: ({ movement: [, y], memo = spring.scrollYValue.getValue(), event }) => {
        event.stopImmediatePropagation()
        const newY = clamp(-y + memo, ...boundsRef.current)
        setScrollYValue({ scrollYValue: newY })
      },
      // onDrag: (state) => console.log(state),
      onWheelStart: () => {
        document.body.classList.add('u-pen')
      },
      onDragStart: () => {
        document.body.classList.add('d-pen')
      },
      onWheelEnd: () => {
        document.body.classList.remove('u-pen')
      },
      onDragEnd: () => {
        document.body.classList.remove('d-pen')
      }
    },
    {
      domTarget: typeof window !== 'undefined' ? window : null,
      drag: {
        filterTaps: true,
        bounds: {
          right: -30
        }
      }
    })

  useEffect(() => {
    !isMobile && bind()
  }, [bind])

  /**
   * save in the store the func to handle the scroll position (for sections)
   * -> workaround on the fact that the context doesn't work
   */
  useEffect(() => {
    if (!isMobile) {
      setStoreSpring({
        spring,
        setScroll: setScrollYValue
      })
    }
  }, [spring, setScrollYValue])

  const randomReward = () => {
    const rewards = [
      'rewards/rewards_extra.mp4',
      'rewards/rewards_genetic.mp4',
      'rewards/rewards_mental.mp4'
    ]
    return rewards[(Math.floor(Math.random() * 3))]
  }

  const randomProject = () => {
    const sites = [
      '//resize.thatsh.it',
      '//simplify.thatsh.it',
      '//instagram.com/explore/tags/gifbashing/'
    ]
    return sites[(Math.floor(Math.random() * 3))]
  }
  /**
   * END SCORLL LOGIC
  */

  const setIndex = useStore('setIndex')
  const tl = useRef()
  const feTurbulence = useRef()
  const primitiveValues = useRef()
  const [filter, setFilter] = useState(null)
  const [question, setQuestion] = useState()

  const waypointEnabled = useStore('waypointEnabled')
  const videoRef = useRef()

  const mouseIn = e => {
    e.target.style.filter = 'url(#filter-1)'
    tl.current && tl.current.restart()
  }

  const mouseOut = e => {
    e.target.style.filter = 'none'
    tl.current && tl.current.progress(1).kill()
  }

  const setRandomQuestion = () => {
    const _i = Math.floor((Math.random() * QUESTIONS.length) - 1)
    // console.log('Question', _i, QUESTIONS[_i])
    setQuestion(QUESTIONS[_i])
  }

  const setEnter = props => {
    // console.log('setEnter', props)
    if (typeof props === 'number' || typeof props === 'string' || props) setIndex(props)
  }

  const setNav = (navItem, prevPosition) => {
    // console.log('setnav', navItem, prevPosition)
    switch (navItem) {
      case 'ahuman':
        if (prevPosition === 'below') {
          if (window && window.location.hash !== '#') window.location.hash = 'ahuman'
        }
        break
      case 'thehaunt':
        if (prevPosition === 'below') {
          if (window) window.location.hash = 'thehaunt'
        } else {
          if (window) window.location.hash = 'ahuman'
        }
        break
      case 'recalling':
        if (prevPosition === 'below') {
          if (window) window.location.hash = 'recalling1993'
        } else {
          if (window) window.location.hash = 'thehaunt'
        }
        break
      case 'info':
        if (prevPosition === 'below') {
          if (window) window.location.hash = 'info'
        } else {
          if (window) window.location.hash = 'recalling1993'
        }
        break
      case '':
        if (prevPosition === 'above') {
          if (window) window.location.hash = ''
        }
        break
      default:
        if (window) window.location.hash = ''
        break
    }
  }

  const playVideo = props => {
    videoRef.current.currentTime = 0
    videoRef.current.play()
  }

  useEffect(() => {
    setRandomQuestion()
    tl.current = gsap.timeline({
      paused: true,
      onStart: () => {
        setFilter('url(#filter-1')
      },
      onComplete: () => {
        setFilter('none')
      }
    })

    feTurbulence.current = document.querySelector('#filter-1 > feTurbulence')
    primitiveValues.current = { turbulence: '0.01 0.01' }

    tl.current.eventCallback('onUpdate', () => {
      if (feTurbulence.current) {
        feTurbulence.current.setAttribute('baseFrequency', primitiveValues.current.turbulence)
      }
    })
    tl.current.to(primitiveValues.current, {
      duration: 0.4,
      ease: 'elastic.easeInOut',
      turbulence: '0.000018 0.018'
    })
    console.log('QUESTAO DO INICIO', question)
  }, [])

  return (
    <>
      {!isMobile && <Scrollbar />}
      <div className={`scroller ${isMobileOnly ? 'isMobile' : ''}`}>
        <Nav />
        <a.div className={`lines ${loaded ? 'loaded' : undefined}`} style={{
          transform: spring.scrollYValue.interpolate(v => `translate3D(0, ${-v}px, 0)`)
        }}>
          <svg width="1676px" height="916px" viewBox="0 0 1676 916" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Artboard">
                <g id="lines">
                  <path id="path-01" d="M9.65,911.77 C231.33,824.28 545.78,769.66 894.54,769.66 C1192.01,769.66 1464.52,809.4 1675.79,875.37" stroke="#FF0000"></path>
                  <path id="path-02" d="M9.65,801.79 C217.48,715.59 518.93,661.38 854.36,661.38 C1176.97,661.38 1468.14,711.53 1674.82,792.05" stroke="#FF0000"></path>
                  <path id="path-03" d="M9.65,572.55 C225.95,562.45 498.88,580.1 791.74,628.72 C1150.21,688.23 1465.43,782.01 1675.61,882.69" stroke="#FF0000"></path>
                  <path id="path-04" d="M9.65,695.47 C186.08,614.39 401.83,536.61 638.77,472.42 C1058.74,358.65 1442.76,315.35 1674.82,343.95" stroke="#FF0000"></path>
                  <path id="path-05" d="M4.29,639.58 C123.91,554.04 259.69,467.58 406.76,384.89 C568.82,293.77 727.67,216.74 874.56,156.12 C1260.88,-3.32 1564.51,-49.26 1626.29,60.62" stroke="#FF0000"></path>
                  <circle className='point circle-1' id="point-1" fill="#FF0000" cx="0" cy="0" r="4"></circle>
                  <circle className='point point-2' id="circle-2" fill="#FF0000" cx="0" cy="0" r="4"></circle>
                  <circle className='point point-3' id="circle-3" fill="#FF0000" cx="0" cy="0" r="4"></circle>
                  <circle className='point point-4' id="circle-4" fill="#FF0000" cx="0" cy="0" r="4"></circle>
                  <circle className='point point-5' id="circle-5" fill="#FF0000" cx="0" cy="0" r="4"></circle>
                </g>
              </g>
            </g>
            <animateMotion
              href="#point-1"
              dur="3.5s"
              repeatCount="indefinite"
              fill="freeze"
              begin=".25s"
              keyTimes="0; 0.7; 1"
              path="M9.65,911.77 C231.33,824.28 545.78,769.66 894.54,769.66 C1192.01,769.66 1464.52,809.4 1675.79,875.37" />
            <animateMotion
              href="#circle-2"
              dur="4.75s"
              repeatCount="indefinite"
              fill="freeze"
              begin=".15s"
              keyTimes="0; 0.7; 1"
              path="M9.65,801.79 C217.48,715.59 518.93,661.38 854.36,661.38 C1176.97,661.38 1468.14,711.53 1674.82,792.05" />
            <animateMotion
              href="#circle-3"
              dur="6s"
              repeatCount="indefinite"
              fill="freeze"
              begin=".52s"
              keyTimes="0; 0.7; 1"
              path="path-03" d="M9.65,572.55 C225.95,562.45 498.88,580.1 791.74,628.72 C1150.21,688.23 1465.43,782.01 1675.61,882.69" />
            <animateMotion
              href="#circle-4"
              dur="4s"
              repeatCount="indefinite"
              fill="freeze"
              begin=".5s"
              keyTimes="0; 0.7; 1"
              path="M9.65,695.47 C186.08,614.39 401.83,536.61 638.77,472.42 C1058.74,358.65 1442.76,315.35 1674.82,343.95" />
            <animateMotion
              href="#circle-5"
              dur="5s"
              begin="1s"
              keyTimes="0; 0.7; 1"
              repeatCount="indefinite"
              fill="freeze"
              path="M4.29,639.58 C123.91,554.04 259.69,467.58 406.76,384.89 C568.82,293.77 727.67,216.74 874.56,156.12 C1260.88,-3.32 1564.51,-49.26 1626.29,60.62" />
          </svg>

        </a.div>
        <a.div
          ref={scrollRef}
          className={`scroller ${loaded && 'loaded'}`}
          style={{
            transform: !isMobile && spring.scrollYValue.interpolate(v => `translate3D(0, ${-v}px, 0)`)
          }}>

          <main>
            <svg style={{
              position: 'absolute',
              overflow: 'hidden',
              width: 0,
              height: 0,
              pointerEvents: 'none'
            }}>
              <defs>
                <filter id="filter-1">
                  <feTurbulence type="fractalNoise" baseFrequency="5" numOctaves="4" result="turbulence" />
                  <feOffset dx="-20" result="warpOffset" />
                  <feDisplacementMap xChannelSelector="R" yChannelSelector="G" scale="60" in="SourceGraphic" in2="warpOffset" />
                </filter>
              </defs>
            </svg>

            <Layout>

              <SEO title="Jen.Lu" />
              <CustomWaypoint style={{ position: 'relative' }} onEnter={() => setEnter('sleep')} onLeave={() => setEnter('wakeup')}
                topOffset={isMobile ? 100 : (isTablet ? 0 : '30%')} />
              <CustomWaypoint style={{ position: 'relative' }} onEnter={({ previousPosition }) => {
                setNav('', previousPosition)
                setEnter('intro')
              }} bottomOffset={isMobileOnly ? '91%' : (isTablet ? '90%' : '80%')} />
              <Project left inView="true" title="A. Human" name="a-human">

                <article className="solo logo">
                  <Image
                    duration={400}
                    loading="eager"
                    alt="olha aqui mae!"
                    fileName="a-human__001"
                    className='glitch'
                  />
                  <Image
                    fileName="a-human_logo"
                    loading="eager"
                    className="a-human-logo"
                    id="a-human-loc"
                  />

                  <p className="title">
                    is about the freedom<br className="mobile-only" /> to be an individual in a society where the media and fashion industry dictate perceptions of beauty in our world. Rather than focusing on familiar, standard body modifications, the <br className="mobile-only" />project is about<br className="mobile-only" /> futuristic possibilities of self-expression.
                  </p>
                  <CustomWaypoint topOffset={isMobile ? '-10%' : 200} onEnter={() => {
                    setEnter(0)
                  }} style={{ marginTop: 0 }} />

                </article>

                <article className='full'>
                  <CustomWaypoint onEnter={({ previousPosition }) => {
                    setNav('ahuman', previousPosition)
                  }
                  } />

                  <Image duration={750} fileName="a-human__002" fileNameMobile="ahuman_showroom_m" className='glitch' />

                  <p className="overlay">
                    <span className='no-break'>A. Human</span> was a theatrical pop-up art experience during New York Fashion Week that invited guests into the showroom of a futuristic fashion brand called <span className="no-break">A. Human</span>. However, instead of designing clothing, <span className="no-break">A. Human</span> designs body modifications.<br />
                    <br />
                    Imagine <a href="https://www.instagram.com/p/BoZFxH3AEKW/" onPointerOver={mouseIn} onPointerOut={mouseOut} target='_blank' rel="noopener noreferrer">eyelashes</a> that move of their own accord, <a href="https://www.instagram.com/p/BoXY1EFgtQ4" onPointerOver={mouseIn} onPointerOut={mouseOut} target='_blank' rel="noopener noreferrer">back filaments</a> that glow, and matching <a href="https://www.instagram.com/p/BoUGzdnAnx0/" target='_blank' rel="noopener noreferrer" onPointerOver={mouseIn} onPointerOut={mouseOut}>hearts</a>  you can design for you and your lover. This is the world of <span className='no-break'>A. Human</span>.
                  </p>
                </article>
                <article className="solo">
                  <p>
                    The sold out experience took the form of a 750 sq. m physical showroom in downtown Manhattan for three weeks, but our presence extended into digital, social media and press headlines across the worlds of fashion, pop culture and entertainment. View the <a href="https://www.instagram.com/p/Bn9Pr2aAD-h/" onPointerOver={mouseIn} onPointerOut={mouseOut} target='_blank' rel="noopener noreferrer">social
                      campaign</a> and <a href="https://www.instagram.com/p/BoHVVRlA68o/" onPointerOver={mouseIn} onPointerOut={mouseOut} target='_blank' rel="noopener noreferrer">art direction</a>.
                  </p>

                  <Image duration={750} fileName="a-human__003" className='glitch' />
                  <div>
                    <CustomWaypoint onEnter={() => setEnter(1)} />
                    <blockquote>
                      “Words like freakish and nightmarish were being used to describe
                      Kim’s new accessory. If something is not the norm, it can’t be
                      admired for its unique beauty. But that’s why projects like <span className="no-break">A. Human</span> should be celebrated.”
                      <cite><a href="https://metro.co.uk/2018/09/04/ive-refused-to-wear-a-prosthetic-but-i-find-the-current-body-modification-trend-empowering-7912564/" onPointerOver={mouseIn} onPointerOut={mouseOut} target='_blank' rel="noopener noreferrer">Metro UK</a></cite>
                    </blockquote>
                  </div>
                </article>
                <article className="article col-2">
                  <Image duration={750} fileName="a-human__004" className='glitch' />
                  <Image duration={750} fileName="a-human__005" className='glitch' />
                  <Image duration={750} fileName="a-human__006" className='glitch' style={{ marginBottom: 0 }} />
                  <Image duration={750} fileName="a-human__007" className='glitch' style={{ marginBottom: 0 }} />
                  <CustomWaypoint onEnter={() => setEnter(2)} bottomOffset={isMobileOnly ? '50%' : '10%'} />
                </article>
                <article className="solo message">
                  <p>
                    As guests entered the space, a message from A. Huxley, founder of <span className='no-break'>A. Human</span>, was projected onto an 8m x 4m wall. The two rectangles at the end of the video outline the entrances. <a href="https://vimeo.com/376432757" onPointerOver={mouseIn} onPointerOut={mouseOut} target='_blank' rel="noopener noreferrer">View</a> the introduction video.
                    <br />
                    <br />
                    Announcer:
                    <br />
                    And now, a message from our founder.
                  </p>
                </article>
                <article className="solo small-gap">
                  <div>
                    <p className='wide'>
                      Voice of A. Huxley:<br />
                      <em>When I founded <span className='no-break'>A. Human</span>, I never dreamed so many of you would share my passion and curiosity for the adornment of the human body. But with your unwavering support and your eagerness to explore what it means to be you, our company has struck out into new territory.

                        <br />
                        <br />Beyond these doors is my most personal collection yet, no detail overlooked, no expense spared. Please take your time, explore, and ask: what if? To my returning clients, welcome back, and I hope you enjoy our newest offerings. To my first-time guests, I humbly offer this advice:
                        <br />
                        <br />
                        Keep an open mind. You may see things you have never imagined, and that may unsettle you in the beginning. If at any time you need assistance, our A.Human associates will be happy to help.
                        <br />
                        <br />
                        Be bold. Dare to explore. Leave the rules of what it means to be “human” at the door. And remember…</em>
                    </p>
                  </div>
                </article>
                <article className="solo small-gap">

                  <Image fileName='a-human_tagline' className='tagline' />
                </article>
                <article className="solo">
                  <div className='tagline'>

                    <Image duration={750} fileName="a-human__008" className='glitch' />
                    <aside className='caption'>
                      A. Human<br />
                      Ursula SS19<br />
                      Enchant and entice with dramatic bioreactive gemstones and cultured keratin that evoke deep sea treasure. Design and placement can be customized to your unique vibe.              </aside>
                  </div>
                  <CustomWaypoint onEnter={() => setEnter(3)} />
                </article>
                <article className="col-2 pieces">
                  <div>
                    <Image duration={750} fileName="a-human__009" className='glitch' />
                    <aside className='caption'>A. Human<br />
                      Corset SS19<br />
                      Give the temple of your body an architectural makeover. Cultured keratin combines with intricate engineering to gird you in flexible, supportive armor.              </aside>
                  </div>
                  <div>
                    <Image duration={750} fileName="a-human__010" className='glitch' />
                    <aside className='caption'>
                      A. Human<br />
                      Empress SS19<br />
                      Embrace your delicate side with lace-like finger webbing, designed to be both durable and ethereal.              </aside>
                  </div>
                  <div style={{ marginBottom: 0 }}>

                    <Image duration={750} fileName="a-human__011" className='glitch' />
                    <aside className='caption'>
                      A. Human<br />
                      Heart SS19<br />
                      Internal. Private. Romantic. For the first time ever, customize what's inside. Honor yourself, or share the most intimate experience of all – a bespoke heart, matched to or designed in coordination with those closest in your life.               </aside>
                  </div>
                  <div style={{ marginBottom: 0 }}>

                    <Image duration={750} fileName="a-human__012" className='glitch' />
                    <aside className='caption'>
                      A. Human <br />
                      Nautilus SS19<br />
                      Embrace your golden ratio with this chambered platform. The biological heel series reshapes the foot into beautiful, nature-inspired drama.
                    </aside>
                  </div>
                </article>
                <article className='full'>
                  <CustomWaypoint onEnter={() => setEnter(4)} />

                  <Image fileName="a-human__013" className='glitch' />
                </article>
                <article className="solo">
                  <p>
                    Fake bodies and live actors with special effects makeup were displayed together to blur the line between fantasy and reality. Uniformed associates assisted clients in picking the right modifications for their future self-expression. Clients were encouraged to “try on” modifications and be creative in the fitting rooms. <a href="https://www.instagram.com/ahuman/" onPointerOver={mouseIn} onPointerOut={mouseOut} target='_blank' rel="noopener noreferrer">View</a> more on A. Human’s Instagram.
                  </p>
                </article>
                <article className="col-3 insta">
                  <Image fileName="a-human__014" className='glitch' />
                  <Image fileName="a-human__015" className='glitch' />
                  <Image fileName="a-human__016" className='glitch' />
                  <Image fileName="a-human__017" className='glitch' />
                  <Image fileName="a-human__018" className='glitch' />
                  <Image fileName="a-human__019" className='glitch' />
                  <Image fileName="a-human__020" className='glitch' />
                  <Image fileName="a-human__021" className='glitch' />
                  <Image fileName="a-human__022" className='glitch' />
                  <Image fileName="a-human__023" className='glitch' />
                </article>
                <article className="solo website">
                  <CustomWaypoint onEnter={() => setEnter('date')} />
                  <div>
                    <CustomWaypoint onEnter={playVideo} />
                    <div style={{
                      paddingBottom: '56.25%',
                      height: 0
                    }} className='a-human--site'>
                      <video ref={videoRef} preload="auto" width="782" autoPlay playsInline muted loop>
                        <source src="videos/ahuman_site.mp4" type="video/mp4" />
                      </video>
                    </div>
                  </div>
                  <Image fileName="a-human__025" className='glitch' />
                  <p style={{ letterSpacing: '-0.0201em' }}>
                    The <a href="//ahuman.jen.lu" target="_blank" rel="noreferrer" onPointerOver={mouseIn} onPointerOut={mouseOut}>website</a> lets clients customize and create a one-of-a-kind <span className="no-break">A. Human</span> heart to (literally) wear on their sleeve. In-store merchandise included tote bags for transporting modifications when not affixed to the body, special towels for drying off modifications during post-procedure care, and edibles (candy) that could be taken daily to relieve body modification muscle soreness.
                  </p>
                </article>
                <CustomWaypoint onEnter={() => setEnter('weather')} />
                <article className="col-3">
                  <Image fileName="a-human__026" className='glitch' />
                  <Image fileName="a-human__027" className='glitch' />
                  <Image fileName="a-human__028" className='glitch' />
                </article>
                <article className="solo">
                  <p>
                    <span className="no-break">A. Human</span> appeared as a challenge on <a href="https://www.bravotv.com/project-runway/season-17/ep-2" onPointerOver={mouseIn} onPointerOut={mouseOut}>Project Runway</a> hosted by <span className="no-break" style={{ letterSpacing: '-0.02em' }}>Karlie Kloss.</span>
                  </p>
                </article>
                <Credits name='A.Human' className='col-4 a-human'>
                  <dl>
                    <dt>Society of Spectacle Team:</dt>

                    <dt>Founder:</dt>
                    <dd>Simon Huck</dd>

                    <dt>Creative Direction:</dt>
                    <dd>Jen Lu</dd>
                    <dd>Spencer LaVallee</dd>

                    <dt>Narration Design:</dt>
                    <dd>Caitlin Starling</dd>

                  </dl>
                  <dl>
                    <dt>Theatrical Direction:</dt>
                    <dd>Michael Counts</dd>
                    <dd>Travis Greisler</dd>
                    <dd></dd>
                    <dt>Set Design & Lighting:</dt>
                    <dd>De-Yan</dd>
                    <dd></dd>
                    <dt>Production & Operation:</dt>
                    <dd>Ghost Experiential</dd>
                    <dd></dd>
                    <dt>Set Design & Fabrication:</dt>
                    <dd>The Factory NYC</dd>
                    <dd>Standard Transmission</dd>
                    <dd>LeapStarr Productions</dd>
                    <dd></dd>
                    <dt>Special Effects Makeup:</dt>
                    <dd>Laura Dandridge</dd>
                    <dd>Conor McCullagh</dd>
                    <dd>and team</dd>

                    <dt>Concept Design:</dt>
                    <dd>Adam Milicevic</dd>
                    <dd>Rayce Bird</dd>
                  </dl>
                  <dl>
                    <dt>Makeup Application:</dt>
                    <dd>Barney Burman</dd>
                    <dd>Robin Pritchard</dd>
                    <dd>Derek Garcia</dd>
                    <dd>and team</dd>
                    <br className="mobile-only" />
                    <dt>Hair:</dt>
                    <dd>Dave Bova</dd>

                    <dt>Actors:</dt>
                    <dd>Matthew Brown</dd>
                    <dd>Jordan Hall</dd>
                    <dd>Dillon Greenberg</dd>
                    <dd>Rebecca Tucker</dd>
                    <dd>Nicole Orabona</dd>
                    <dd>Keith Comley</dd>
                    <dd>Arden Winant</dd>
                    <dd>Steve R. Austin</dd>
                    <dd>Ari Gold</dd>
                    <dd>Jessy Smith</dd>
                    <dd>Stasi Berezovskaya</dd>
                    <dd>Ann Devine</dd>
                    <dd>Saige Bynum</dd>

                  </dl>
                  <dl>
                    <dt></dt>
                    <dd>Jeff Garber</dd>
                    <dd>Justin Mock</dd>
                    <dd>Jason Norris</dd>
                    <dd>Delicia Glam</dd>
                    <dd>Joyce Miller</dd>
                    <dd>Reema Bounajem</dd>
                    <dd>Brian Alford</dd>
                    <dd>Mark Blane</dd>
                    <dd>Casey Cudmore</dd>
                    <dd>Molly Marx</dd>

                    <dt>Digital:</dt>
                    <dd>Nelson Vassalo</dd>
                    <dd>Pedro Roxo Nogueira</dd>
                    <dd>Rui Iniesta Furtado</dd>

                    <dt>PR:</dt>
                    <dd>PR Consulting</dd>

                    <dt>Photography:</dt>
                    <dd>Luke Abby</dd>
                    <dd>Josiah Spencer</dd>

                    <dt>Special Thanks:</dt>
                    <dd>Neely Lisk</dd>
                  </dl>
                </Credits>
              </Project>
              {/* </Step> */}
              <Project title="The Haunted" inView="false" name="the-haunted">
                <article className="solo">
                  <div className="sticker sticker-01">
                    <Image fileName="the-haunt_sticker__01" />
                  </div>

                  <Image fileName="the-haunt_logo" style={{ marginBottom: '1.625em' }} id="the-haunt-loc" />
                  <CustomWaypoint onEnter={({ previousPosition }) => {
                    setEnter(5)
                    setNav('thehaunt', previousPosition)
                  }} />
                  <p className="title" style={{ marginBottom: '2.25rem' }}>
                    is a poppin' haunted nightclub in the heart of Miami where guests
                    party among the spirits and dance <br className="desktop-only" />till they die.
                  </p>
                  <p className="haunt-p">
                    What makes Art Basel fun is not the art itself, but the vibrancy of the city that it’s viewed in. Before Miami was a bustling art scene, it was a safe haven for Cubans, who put it on the map. It was the epicenter of LGBTQ culture, giving the city its glamorous reputation. It was called the “Drug Capital of the World,” the city that cocaine built. It was the home to famous people and famous scandals.
                    <br />
                    <br />
                    <span className="no-break">The Haunt</span> opened during Art Basel. At <span className="no-break">The Haunt</span>, Miami's past and
                    future mingle, making it apparent that the heart and soul of this
                    city is as eternal as the ghosts in our exhibit.
                  </p>
                </article>
                <article className='full'>
                  <div className="sticker sticker-02">
                    <Image fileName="the-haunt_sticker__02" />
                  </div>
                  <Image fileName="the-haunt__001" className='glitch' />
                </article>
                <article className="solo">
                  <CustomWaypoint onEnter={() => setEnter(9)} />
                  <div className='wine-spirits'>
                    <div style={{
                      paddingBottom: '28.71428571%',
                      height: 0
                    }} dangerouslySetInnerHTML={{
                      __html: `
                      <video autoplay loop muted playsinline style='margin-bottom: 2.25rem'>
                          <source src='videos/thehaunt_neon.mp4' type="video/mp4" />
                        </video>
                      `
                    }}>
                    </div>
                  </div>
                  <p>
                    Outside <span className="no-break">The Haunt</span>, there was a sign, a velvet rope, and a bouncer (the gatekeeper) ushering people into the space. Guests entered the vibrant nightclub, where a <a href="https://soundcloud.com/user-328972915/club-mix" onPointerOver={mouseIn} onPointerOut={mouseOut} target='_blank' rel="noopener noreferrer">bespoke mix of records</a> were spinning and drinks awaited them.
                  </p>
                </article>
                <article className="col-2">
                  <Image fileName="the-haunt__002" className='glitch' />
                  <Image fileName="the-haunt__003" className='glitch' />
                </article>
                <article className="solo floating">

                  <Image className='records' fileName="the-haunt__004" />
                  <CustomWaypoint onEnter={() => setEnter(7)} />
                  <p>
                    Guests viewed 3D ghost interpretations of real historical Miami figures through augmented reality, uncovering peculiar tales of the people whose after-hour parties became
                    parties. Augmented reality plaques with <a href="http://thehaunt.jen.lu/about.php" onPointerOver={mouseIn} onPointerOut={mouseOut} target='_blank' rel="noopener noreferrer">stories</a> floated next to each spirit.
                  </p>
                </article>

                <article className="characters">
                  <div className="left-side">
                    <figure className="character queen">
                      <Image fileName="the-queen__pic" className="queen-img" />
                      <figcaption>
                        {isMobileOnly
                          ? (<img loading='lazy' src={require('../images/thehaunt_queen_title_m.png')} alt="" />)
                          : (<img loading='lazy' src={require('../images/thehaunt_queen_title2.png')} alt="" />)
                        }
                        <p className="th-caption">South Beach Wanda was the ultimate 90’s
                          drag queen icon and was murdered tragically a few short years before the exhibit.
                          She performed on the dance floor at <span className="no-break">The Haunt</span>.
                        </p>
                        <CustomWaypoint onEnter={() => setEnter(8)} />
                      </figcaption>
                    </figure>
                  </div>
                  <div className="right-side">
                    <div className="top">
                      <figure className="character mother">
                        <Image fileName="the-mother__pic" />
                        <figcaption>
                          {isMobileOnly
                            ? <img loading='lazy' src={require('../images/thehaunt_mother_title_m.png')} alt="" />
                            : <img loading='lazy' src={require('../images/thehaunt_mother_title.png')} alt="" />
                          }
                          <p className="th-caption">Julia Tuttle was the mother of Miami and the founder of The Haunt – the original Miami spirit.</p>
                        </figcaption>
                      </figure>
                      <figure className="character botanist">
                        <Image fileName="the-botanist__pic" />
                        <figcaption>
                          {isMobileOnly
                            ? <img loading='lazy' src={require('../images/thehaunt_botanist_title_m.png')} alt="" />
                            : <img loading='lazy' src={require('../images/thehaunt_botanist_title.png')} alt="" />
                          }
                          <p className="th-caption">David Fairchild was a famous botanist every Miami student learns about in school. He tended to the plants at the bar, the only living thing at The Haunt.</p>
                        </figcaption>
                      </figure>
                      <figure className="character cowboy">
                        <Image fileName="the-cowboy__pic" />
                        <figcaption>
                          {isMobileOnly
                            ? <img loading='lazy' src={require('../images/thehaunt_cowboy_title_m.png')} alt="" />
                            : <img loading='lazy' src={require('../images/thehaunt_cowboy_title.png')} alt="" />
                          }
                          <p className="th-caption">Alberto Bravo, one of the ex-husbands of drug lord Griselda Blanco, guarded his hidden box of cocaine at The Haunt.</p>
                        </figcaption>
                      </figure>
                    </div>

                    <div className="bottom">
                      <figure className="character designer">
                        <Image fileName="the-designer__pic" />
                        <figcaption>
                          {isMobileOnly
                            ? <img loading='lazy' src={require('../images/thehaunt_designer_title_m.png')} alt="" />
                            : <img loading='lazy' src={require('../images/thehaunt_designer_title.png')} alt="" />
                          }
                          <p className="th-caption">Objects were used to symbolize some ghosts, such as Gianni Versace, whose ghost attempted to call the police when he was shot but there’s no reception in the afterlife.</p>

                        </figcaption>
                      </figure>
                      <figure className="character bartender">
                        <Image fileName="the-bartender__pic" />
                        <figcaption>
                          {isMobileOnly
                            ? <img loading='lazy' src={require('../images/thehaunt_bartender_title_m.png')} alt="" />
                            : <img loading='lazy' src={require('../images/thehaunt_bartender_title.png')} alt="" />
                          }
                          <p className="th-caption">Mac Klein, owner of Mac’s Club Deuce bar, wore his signature Hawaiian printed shirt as he tended the bar at The Haunt.</p>
                        </figcaption>
                      </figure>
                      <figure className="character activist">
                        <Image fileName="the-activist__pic" />
                        <figcaption>
                          {isMobileOnly
                            ? <img loading='lazy' src={require('../images/thehaunt_activist_title_m.png')} alt="" />
                            : <img loading='lazy' src={require('../images/thehaunt_activist_title.png')} alt="" />
                          }
                          <p className="th-caption">Our exhibit took place at Ocean Terrace Hotel, an art deco building that was slated to be demolished shortly after our exhibit ended. Barbara Capitman, the activist who saved South Beach’s Art Deco district, rallied to save Ocean Terrace at The Haunt.</p>
                        </figcaption>

                      </figure>
                    </div>
                  </div>

                </article>
                <article className="solo">
                  <div className='haunt-special--article'>

                    <blockquote>
                      “...The Haunt, even used [Miami] as inspiration, constructing a nightclub for our deceased local icons, serving cocktails and using augmented reality to make the ghosts appear at the club. <span className="no-break">Gianni Versace</span> is here, but so is <span className="no-break">Julia Tuttle</span>. <span className="no-break">Mac Klein</span> tends the bar. These organizers did their research.”
                      <cite><a href="https://www.miaminewtimes.com/arts/art-basel-miami-beach-2017-reviewed-by-a-first-timer-9900491" onPointerOver={mouseIn} onPointerOut={mouseOut} target='_blank' rel="noopener noreferrer">Miami New Times</a></cite>
                    </blockquote>
                  </div>
                </article>
                <CustomWaypoint onEnter={() => setEnter(10)} />
                <article className="full">
                  <div className="sticker sticker-03">
                    <Image fileName="the-haunt_sticker__03" />
                  </div>

                  <Image fileName="the-haunt__005" className='glitch lost-boy' style={{ marginBottom: '2.25rem' }} />
                  <figcaption className="the_haunt_lost_boy">
                    {isMobile
                      ? <img loading='lazy' src={require('../images/thehaunt_boy_title_m.png')} />
                      : <img loading='lazy' src={require('../images/the-lost-boy__caption.png')} alt="" />
                    }
                    <p>The Lost Boy represented the thousands of Cuban children that were sent to Miami without their parents in the ‘50s for a shot at a better life, building Miami into what it is today.
                    </p>
                  </figcaption>
                </article>
                <article className="col-2">
                  <section>
                    <CustomWaypoint onEnter={() => setEnter(11)} />
                    <Image fileName="the-haunt__006" className='glitch flamingo' style={{ marginBottom: '2.25rem' }} />
                    <figcaption style={{ marginBottom: 0 }} className="the_haunt_flamingo">
                      {isMobile
                        ? <img loading='lazy' src={require('../images/thehaunt_flamingo_title_m.png')} />
                        : <img loading='lazy' src={require('../images/the-flamingo__caption.png')} alt="" />
                      }
                      <p>The exhibit took place in a room in the Ocean Terrace Hotel. The Flamingo in the bathroom is inspired by zoo assistant Ron Magil’s photography of flamingos that were sheltered in a bathroom during Hurricane Andrew.</p>
                    </figcaption>
                  </section>
                  <section>
                    <Image fileName="the-haunt__007" className='glitch entertainer' style={{ marginBottom: '2.25rem' }} />
                    <figcaption style={{ marginBottom: 0 }} className="the_haunt_entertainer">
                      {isMobile
                        ? <img loading='lazy' src={require('../images/thehaunt_entertainer_title_m.png')} />
                        : <img loading='lazy' src={require('../images/the-entertainer__caption.png')} alt="" />
                      }
                      <p style={{ maxWidth: '315px' }}>Infamous controversial R&B singer Blowfly had a permanent gig at The Haunt for eternity, literally spinning records.
                      </p>
                    </figcaption>
                  </section>
                </article>

                <CustomWaypoint onEnter={() => setEnter(6)} />
                <article className="col-3">
                  <Image fileName="the-haunt__008" className='glitch' />
                  <div>
                    <div style={{
                      paddingBottom: '100%',
                      height: 0
                    }} dangerouslySetInnerHTML={{
                      __html: `
                      <video autoplay loop muted playsinline>
                        <source src="videos/thehaunt_ghost2.mp4" type="video/mp4" />
                      </video>`
                    }}>

                    </div>
                  </div>
                  <Image fileName="the-haunt__010" className='glitch' />
                </article>

                <article className="solo">

                  <div>
                    <p>
                      Guests unknowingly became part of The Haunt by discreetly being
                      scanned via Kinect, then placed onto the dance floor in real-time with their
                      own story, so their ghosts party on forever. Over the course of
                      the four days, the haunt’s website grew from a few
                      Miami-inspired ghosts to a crowded nightclub filled with the
                      ghosts of present-day Miami partiers. <span className="no-break"><a href="https://vimeo.com/257503015" onPointerOver={mouseIn} onPointerOut={mouseOut} target='_blank' rel="noopener noreferrer">View</a> The Haunt case study.</span>
                    </p>

                  </div>
                </article>
                <article className="small">
                  <Image fileName="the-haunt__011" />
                </article>
                <Credits name='The Haunt' className='col-3 haunt'>
                  <dl>
                    <dt>Artists:</dt>
                    <dd>Jen Lu</dd>
                    <dd>Fati Jafri</dd>
                    <dd>Annie Spellings</dd>
                    <dd>Kleinmania</dd>

                    <dt>AR-tists:</dt>
                    <dd>Marpi</dd>
                    <dd>Andy Mai</dd>

                    <dt>Music:</dt>
                    <dd>Toga FM</dd>

                    <dt>Photography:</dt>
                    <dd>Walter Wlodarczyk</dd>

                  </dl>
                  <dl>
                    <dt>Special Thanks:</dt>
                    <dd>Alex Fuzzywobble</dd>
                    <dd>Jess Humphrey</dd>
                    <dd>Mauricio Lopez</dd>
                    <dd>Carlos Turcios</dd>
                    <dd>Gilbert Good Jr.</dd>
                    <dd>Paul Ramirez</dd>
                    <dd>Alex Reyes</dd>
                    <dd>Ondi</dd>
                    <dd>Judah Damiani</dd>
                    <dd>Spencer LaVallee</dd>
                    <dd>Chloe Karayiannis</dd>
                    <dd>Robert Tod</dd>
                    <dd>Doa Jafri</dd>
                    <dd>Pam Rosales</dd>
                    <dd>True Colors Neon</dd>
                  </dl>
                  <dl>
                    <dt>Sponsors:</dt>
                    <dd>Giphy Arts</dd>
                    <dd>Mason’s Mill & Lumber Co.</dd>
                    <dd>Occipital</dd>
                    <dd>itSeez3D</dd>

                    <dt>Art Organization:</dt>
                    <dd>Satellite Art Show</dd>
                  </dl>
                </Credits>
              </Project>

              <section className="retrieve" onClick={e => window && window.open(randomProject(), '_blank')}>
                {/* <Image fileName="retrieve" /> */}
                <CustomWaypoint onEnter={({ previousPosition }) => {
                  setEnter(12)
                  setNav('recalling', previousPosition)
                }} bottomOffset={isMobileOnly ? '30%' : 0} />
                {isMobile
                  ? <div
                    dangerouslySetInnerHTML={{
                      __html: `
                    <video preload="auto" autoplay loop muted playsinline>
                      <source src='/videos/banner_access_m.mp4' type="video/mp4" />
                    </video>
                    `
                    }}>
                  </div>
                  : <div>
                    <video preload='auto' autoPlay loop muted playsInline>
                      <source src={getVideoSrc('archive')} type="video/mp4" />
                    </video>
                  </div>
                }

                {/* <img src="retrieve.svg" alt=""/> */}
              </section>
              <Project left title="Recalling 1993" name="remember">
                <article className="solo logo">

                  <Image fileName="recalling_logo" id="remember-loc" />
                  <CustomWaypoint onEnter={() => setEnter(13)} bottomOffset={isMobileOnly ? '50%' : 0} />
                  <p className='title'>
                    transformed every surviving pay phone in Manhattan into a geolocated portal, going back to a year that saw dramatic changes in visual art, music, advertising, politics, literature and the media.
                  </p>
                  <p className="title remember-special">
                    It was created in conjunction with the <span className="no-break">New Museum’s</span> building-wide exhibition, <em>NYC 1993: Experimental Jet Set, Trash and No Star</em>, which featured art made or shown in New York during this seminal year.
                  </p>
                </article>
                <article className='full'>
                  <Image fileName="recalling__001" className='glitch' className='glitch' />
                </article>
                <article className="solo">
                  <div>
                    <aside className='caption' style={{
                      textAlign: 'center',
                      maxWidth: '100%'
                    }}>
                      <span className="no-break">Recalling 1993</span> was referenced as a pick-up line in romantic comedy <span className="no-break"><em>Before We Go</em></span>, starring <span className="no-break">Chris Evans.</span>
                    </aside>

                    <CustomWaypoint onEnter={() => setEnter(15)} />
                    <div className="recalling-video" dangerouslySetInnerHTML={{
                      __html: `
                      <video autoplay loop muted playsinline>
                        <source src="videos/recalling1993_beforewego.mp4" type="video/mp4" />
                      </video>
                      `
                    }}>
                    </div>
                  </div>
                </article>
                <article className="how-1993">
                  <div>
                    <div style={{
                      paddingBottom: '87.09677419%',
                      height: 0
                    }}>
                      <img src="videos/recalling1993_how.gif" width="775" alt="" loading='lazy' />
                    </div>
                  </div>

                  <div>
                    <p>How does it work?</p>
                    <ol>
                      <li>
                        To access Recalling 1993, anyone could dial toll-free <span className="no-break">1-[855]-FOR-1993</span> from any Manhattan pay phone.
                      </li>
                      <li>
                        Our database determined the phone’s <span className="no-break">locations in Manhattan</span>.
                      </li>
                      <li>
                        Once connected, the caller heard <a href="https://www.dropbox.com/sh/yk1npn4fi1652a8/AAAgjYtgzD2etkg7-cSIWjfea?dl=0" target='_blank' rel="noopener noreferrer" onPointerOver={mouseIn} onPointerOut={mouseOut}>stories</a> of sweeping change and personal experiences tied to the neighborhood where they stood. Each story ended with an invitation to visit the <span className="no-break">New Museum</span> exhibit.
                      </li>
                    </ol>
                  </div>
                </article>

                <article className="solo">
                  <div>

                    <blockquote>
                      “In my 30 years of <br className="mobile-only" />museum experience, I’ve never seen a campaign <br className="mobile-only" />of
                      this magnitude for <br className="desktop-only" />an exhibition.”
                      <cite>Lisa Phillips,<br className="mobile-only" /> <em>Executive Director of the <span className="no-break">New Museum</span></em></cite>
                    </blockquote>
                  </div>
                </article>
                <CustomWaypoint onEnter={() => setEnter(14)} />

                <article className="col-3" style={{ position: 'relative' }}>

                  <Image fileName="recalling__004" className='glitch' />
                  <Image fileName="recalling__005" className='glitch' />
                  <Image fileName="recalling__006" className='glitch' />

                  <Image fileName='manhattan'
                    style={{
                      position: 'absolute',
                      width: '98%',
                      top: '100%',
                      right: '-2.25rem',
                      zIndex: -1,
                      overflow: 'hidden'
                    }} />
                </article>

                <article className="solo case-1993">
                  <p>From Mario Batali recalling the opening of his first restaurant in the West Village to Robin Byrd remembering Times Square’s sordid past, uncensored memories from these and a myriad of other notable <br className="mobile-only" />New Yorkers bring listeners back to a very different New York from the one we know today. <a href="https://vimeo.com/62832865" onPointerOver={mouseIn} onPointerOut={mouseOut} target='_blank' rel="noopener noreferrer">View</a> the <span className="no-break">Recalling 1993 case study.</span></p>
                  <dl>
                    <dt>Historical narrators for <div className="no-break">Recalling 1993</div> included:</dt>
                    <dd>Brian Lehrer, <i>WNYC Radio Host</i></dd>
                    <dd>Michael Musto, <i>Village Voice Columnist</i></dd>
                    <dd>Timothy “Speed” Levitch, <i>New York City Tour Guide</i></dd>
                  </dl>
                  <CustomWaypoint onEnter={() => setEnter(17)} />

                  <dl>
                    <dt>Other <span className="no-break">Recalling 1993</span> contributors included:</dt>
                    <dd>Abby Moser, <i>Riot Grrrl Documentarian</i></dd>
                    <dd>Jim Abbot, <i>MLB Pitcher</i></dd>
                    <dd>Joshua David, <i>High Line President</i></dd>
                    <dd>Chazz Palminteri, <i>Actor</i></dd>
                    <dd>Fern Mallis, <i>New York Fashion Week Creator</i></dd>
                    <dd>Johnny Puke, <i>Punk Rocker</i></dd>
                    <dd>David Ortiz, <i>Streetwear Legend</i></dd>
                    <dd>James St. James, <i>Club Kid</i></dd>
                    <dd>Sister Miriam Kevin Phillips, <i>Sisters of Charity</i></dd>
                  </dl>
                </article>
                <div className="testimonials">

                  <aside className='right'>
                    <Image fileName="recalling__007" />
                    <blockquote>
                      “&nbsp;The ceiling shook and plaster started hitting all the one name supermodels of the day.”
                      <a href='https://www.dropbox.com/s/rlq1a7zbsa6ufti/1_Fern_Mallis.aif?dl=0' onPointerOver={mouseIn} onPointerOut={mouseOut} target='_blank' rel="noopener noreferrer">Listen to Fern Mallis / Midtown East</a>
                    </blockquote>
                  </aside>
                  <aside>
                    <Image fileName="recalling__008" />
                    <blockquote>
                      “&nbsp;Most of the bums <br />on the streets are really <br />undercover cops.”
                      <a href='https://www.dropbox.com/s/qkaum9x08428qyc/1_RobinByrd_TimesSquare.aif?dl=0' onPointerOver={mouseIn} onPointerOut={mouseOut} target='_blank' rel="noopener noreferrer">Listen to Robin Byrd / Midtown West</a>
                    </blockquote>
                  </aside>
                  <aside className='right'>
                    <Image fileName="recalling__009" />
                    <blockquote>“&nbsp;You had to crawl under a fence and drag yourself through gravel and broken glass to walk on The High Line.”
                      <a href='https://www.dropbox.com/s/wqjl5evmrgte9sf/1_JOSH_DAVID_HIGHLINE.aif?dl=0' onPointerOver={mouseIn} onPointerOut={mouseOut} target='_blank' rel="noopener noreferrer">Listen to Joshua David / Chelsea</a>
                    </blockquote>
                  </aside>
                  <CustomWaypoint onEnter={() => setEnter(16)} />
                  <aside>
                    <Image fileName="recalling__010" />
                    <blockquote>
                      “&nbsp;I saw both the worst of it <br />and the hope that ultimately <br />was able to be offered.”
                      <a href='https://www.dropbox.com/s/5sm1nt05b4keqrt/1_Sister_KevinStVincentsAIDS.aif?dl=0' onPointerOver={mouseIn} onPointerOut={mouseOut} target='_blank' rel="noopener noreferrer">Listen to Sister Kevin at St. Vincent’s Hospital /
                        Greenwich Village</a>
                    </blockquote>
                  </aside>
                  <aside className='right'>
                    <Image fileName="recalling__011" />
                    <blockquote>
                      “&nbsp;He chopped her up and <br />made stew out of her <br />in my apartment.”
                      <a href='https://www.dropbox.com/s/mbo8e27zz2v54m7/1_JamesStJames_AfterAfterHours.aif?dl=0' onPointerOver={mouseIn} onPointerOut={mouseOut} target='_blank' rel="noopener noreferrer">Listen to James St. James / East Village</a>
                    </blockquote>
                  </aside>
                </div>
                <article className="solo">
                  <p className="about">
                    “This is the most incredible idea of the year. It’s better
                    programming than HBO. How the hell did they pull this off?”
                    <small>Will Bourne, <br className="mobile-only" /><em>Editor in Chief of The Village Voice</em></small>
                  </p>
                </article>
                {/* <CustomWaypoint onEnter={() => setEnter(21)} /> */}
                <Credits name='Recalling 1993' className='col-3 recalling'>
                  <dl>
                    <dt>Client:</dt>
                    <dd>New Museum</dd>

                    <dt>Agency:</dt>
                    <dd>Droga5</dd>

                    <dt>Founder:</dt>
                    <dd>David Droga</dd>

                    <dt>Creative:</dt>
                    <dd>Jerry Hoak</dd>
                    <dd>Ray Del Savio</dd>
                    <dd>Jen Lu</dd>
                    <dd>Colin Lord</dd>
                    <dd>Daniël Sumarna</dd>
                    <dd>Bryan Wolff</dd>

                    <dt>Strategy:</dt>
                    <dd>Matt Gardner</dd>
                    <dd>P.J. Mongell</dd>
                  </dl>

                  <dl>

                    <dt>Production:</dt>
                    <dd>Sally-Ann Dale</dd>
                    <dd>Scott Chinn</dd>
                    <dd>Jennifer Mckenzie</dd>
                    <dd>Goldie Robbens</dd>
                    <dd>Lindsey Slaby</dd>
                    <dd>Ian Graetzer</dd>

                    <dt>Digital:</dt>
                    <dd>David Justus</dd>
                    <dd>Fran Devinney</dd>

                    <dt>Design:</dt>
                    <dd>Kathrin Hoffman</dd>
                    <dd>Eileen Tang</dd>
                    <dt>Print Production:</dt>
                    <dd>Rob Lugo</dd>
                    <dd>Jeannie O'toole</dd>
                    <dd>Annick Thomas</dd>
                  </dl>

                  <dl>
                    <dt>Research:</dt>
                    <dd>Sarah Gancher</dd>
                    <dd>Amelia Barry</dd>
                    <dd>Bo Jacober</dd>

                    <dt>Partners:</dt>
                    <dd>Village Voice</dd>
                    <dd>Van Wagner</dd>
                    <dd>Gawker</dd>

                    <dt>Photography:</dt>
                    <dd>Paul McGeiver</dd>

                    <dt>Video Editor:</dt>
                    <dd>Matt Badger</dd>
                  </dl>
                </Credits>
              </Project>

              <section className='tomb' id="tomb-loc">
                <CustomWaypoint onEnter={({ previousPosition }) => setNav('info', previousPosition)} />
                <aside>
                  <Image fileName='monolith' />
                  <div className="content">
                    <h3>Hello visitor,<br /><br />
                      Turn on speakers and help train dot by answering this question: <br /><br />{question}</h3>
                    <Form question={question} />
                  </div>

                </aside>
                <article id='about'>
                  <Image fileName='bars' />
                  {/* <img src="bars.svg" alt="" /> */}

                  <p>Information Available For Public Disclosure:</p>
                  <p>
                    DOT is an Artificial Artificial Intelligence, an agent trained under a single-input dataset that was acquired from a human named Jen Lu.
                  </p>
                  <p>
                    I’m Jen Lu. My friends call me Jen Dot Lu.</p>
                  <p>
                    I’m a narrative-driven experiential artist with a focus on physical and digital world-building. It’s where marketing communication and design principles converge to bring you compelling human experiences.
                  </p>
                  <ul className='skills'>
                    <li>Creative Direction & Design</li>
                    <li>Concept Development</li>
                    <li>Creative Strategy</li>
                  </ul>
                  <CustomWaypoint onEnter={() => setEnter('outro')} />

                  <p>Peep my <a href='//instagram.com/jendotlu' onPointerOver={mouseIn} onPointerOut={mouseOut} target='_blank' rel="noopener noreferrer">Instagram</a>.<br />
                    Let's make something together: <span aria-hidden='true'>jen@jen.lu</span><br />
                    Special thanks to <a href="//instagram.com/nelson_vassalo" onPointerOver={mouseIn} onPointerOut={mouseOut} target='_blank' rel="noopener noreferrer">@nelson_vassalo</a>, <a href="//twitter.com/mrdoob" onPointerOver={mouseIn} onPointerOut={mouseOut} target='_blank' rel="noopener noreferrer">@mrdoob</a> & <a href="//instagram.com/cplu" target='_blank' rel="noopener noreferrer" onPointerOver={mouseIn} onPointerOut={mouseOut}>@cplu</a>.</p>
                </article>

              </section>

            </Layout>
            <section className='reward' onClick={e => {
              const windowContent = `
<!doctype html><html style="oferflow: hidden"><head><title></title><meta charset="UTF-8" /></head>
<body style='margin: 0; padding: 0;background: #000'>
  <div id="html_contents" data-new-window>
    <video loop autoplay muted playinsline style="width: 100%; height: auto; padding: 0; margin: 0; display: block;">
      <source src="${randomReward()}" type="video/mp4" />
    </video>
  </div>
  <script>
  var video = document.body;
  window.innerHeight = video.height;
  </script>
  </head>
<body>`
              const w = 1920
              const h = 1080
              const screenLeft = window && window.screenLeft !== undefined ? window.screenLeft : window.screenX
              const screenTop = window && window.screenTop !== undefined ? window.screenTop : window.screenY

              const width = window && window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width
              const height = window && window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height

              const systemZoom = window && width / window.screen.availWidth
              const left = (width - w) / 2 / systemZoom + screenLeft
              const top = (height - h) / 2 / systemZoom + screenTop
              const newWindow = window.open('about:blank', '_blank', `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${w},height=${h},top=${top},left=${left}`)
              newWindow.document.write(windowContent)
              // window && window.open(`${randomReward()}`, '_blank')
            }
            }>
              {isMobile
                ? <article className="full" style={{
                  paddingBottom: '40.94707521%',
                  height: 0
                }}
                  dangerouslySetInnerHTML={{
                    __html: `
                  <video preload="auto" autoplay loop muted playsinline>
                    <source src='/videos/banner_retrieve_m.mp4' type="video/mp4" />
                  </video>
                  `
                  }}>
                </article>
                : <article className="full" style={{
                  paddingBottom: '40.94707521%',
                  height: 0
                }}>
                  <video preload="auto" autoPlay loop muted playsInline>
                    <source src={getVideoSrc('reward')} type="video/mp4" />
                  </video>
                </article>
              }

            </section>
          </main>
          {/* </div> */}

        </a.div>
        <Dot />
        {/* {!isMobile && <FXLayer spring={spring} />} */}
      </div>
    </>
  )
}

export default IndexPage
