const PHRASES = [

  {
    phrase: `You're now viewing
 A. Human.`,
    file: '/speeches/3.mp3',
    title: true,
    pace: 230,
    width: 320
  },
  {
    phrase: `Kim's necklace lights
 up and beats to the
 rhythm of her heart.`,
    file: '/speeches/4.mp3',
    pace: 210,
    width: 420
  },
  {
    phrase: `Makeup application took
 up to three hours.`,
    file: '/speeches/5.mp3',
    pace: 225,
    width: 440
  },
  {
    phrase:
      'A.Human appeared in Vogue, Vanity Fair, W Mag, Cosmopolitan, Elle, Harper\'s Bazaar and many others.',
    file: '/speeches/6.mp3',
    pace: 400,
    width: 480
  },
  {
    phrase:
      `Our makeup team is
 also known for The Avengers,
 Guardians of the Galaxy
 and Captain America.`,
    file: '/speeches/7.mp3',
    pace: 300,
    width: 480
  },
  {
    phrase: `You're now viewing
 The Haunt.`,
    file: '/speeches/8.mp3',
    pace: 200,
    title: true,
    width: 320
  },
  {
    phrase:
      `The Haunt is the first
 exhibit to transform and
 place 3D partygoers in
 real time Augmented Reality.`,
    file: '/speeches/9.mp3',
    pace: 300,
    width: 480
  },
  {
    phrase: 'Clear tombstone shaped records with bespoke music were sold at The Haunt.',
    file: '/speeches/10.mp3',
    pace: 310,
    width: 480
  },
  {
    phrase: `The ghosts were 3D scans
 of live models who wore
 actual costumes.`,
    file: '/speeches/11.mp3',
    pace: 240,
    width: 440
  },
  {
    phrase: 'The Haunt was sponsored by Giphy Arts, Occipital and ITSEEZ3D.',
    file: '/speeches/12.mp3',
    pace: 320,
    width: 440
  },
  {
    phrase: `Happiness lies within
 and not from your
 surroundings.`,
    file: '/speeches/13.mp3',
    pace: 300,
    width: 420
  },
  {
    phrase: `To be truly human,
 one must venture into
 uncharted waters.`,
    file: '/speeches/14.mp3',
    pace: 300,
    width: 420
  },
  {
    phrase: `Would you be
 interested in an
 archived project?`,
    file: '/speeches/15.mp3',
    pace: 220,
    width: 440,
    mobileWidth: 400
  },
  {
    phrase: `You're now viewing 
 Recalling 1993.`,
    file: '/speeches/16.mp3',
    title: true,
    pace: 300,
    width: 340
  },
  {
    phrase: `Hundreds of stories
 were delivered to
 over 5,000 pay phones.`,
    file: '/speeches/17.mp3',
    pace: 300,
    width: 420
  },
  {
    phrase: `Recalling 1993
 inspired Captain America.`,
    file: '/speeches/18.mp3',
    pace: 470,
    width: 440
  },
  {
    phrase: `The thread of human
 hope is spun from the
 flax of sorrow.`,
    file: '/speeches/20.mp3',
    pace: 200,
    width: 420
  },
  {
    phrase:
      `Humans cannot
 accomplish anything
 without holding
 on to something.`,
    file: '/speeches/19.mp3',
    pace: 300,
    width: 420
  },
  {
    phrase: `Humans cannot
 create anything out
 of nothingness.`,
    file: '/speeches/21.mp3',
    pace: 300,
    width: 420
  }
]

export const QUESTIONS = [
  'What is love?',
  "What is real and what isn't?",
  'What was the last thing that made you cry?',
  'What was the last thing that made you smile?',
  'What are thoughts and where do they come from?',
  'What is happiness?',
  'What is sadness?',
  'What is consciousness?'
]

export default PHRASES
