import React, { useEffect, useRef, useState } from 'react'
import { Waypoint } from 'react-waypoint'
import useStore from '../utils/useStore.js'

export default ({ children, ...props }) => {
  const scrollValue = useStore('scrollValue')
  const [state, setState] = useState(0)
  const color1 = useRef(Math.floor(Math.random() * 155 + 100))
  const color2 = useRef(Math.floor(Math.random() * 155 + 100))

  useEffect(() => {
    setState(scrollValue)
  }, [scrollValue])

  return (
    <div style={{
      transform: `translateZ(${state})`,
      background: `rgba(${color1.current}, ${color2.current}, 255, 1)`,
      ...props.style
    }}>
      <Waypoint {...props} onPositionChange={() => {}}>
        {children}
      </Waypoint>
    </div>
  )
}
