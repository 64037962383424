/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
// import Hammer from "hammerjs";
// import Scroll from '../components/scroll'
import '../scss/main.scss'

import useStore from '../utils/useStore'

import Header from './header'
import useDeviceDetect from '../components/isMobile'

const Layout = ({ children }) => {
  // const scrollRef = useRef()
  // useEffect(() => {
  //   new Scroll(scrollRef)
  // }, [])
  // useEffect(() => {
  //   const scroll = new LocomotiveScroll({
  //     el: el.current,
  //     smooth: true,
  //     inertia: 1
  //   })
  //   return function () {
  //     scroll.destroy()
  //     if (el) el.current.className = ''
  //     if (el) el.current.dataset.scroll = ''
  //   }
  //   // return scroll.destroy;
  // }, [el])
  const { isMobile } = useDeviceDetect()

  return (
    <>
      <div className={`scroller ${isMobile === true ? 'isMobile' : ''}`}>
        <Header />
        <main>
          {/* <Model /> */}
          {children}
        </main>
        {/* <footer>© {new Date().getFullYear()}</footer> */}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
