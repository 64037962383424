import React from 'react'

const Project = props => {
  // const checkView = () => {
  //     if (props.inView) {
  //         setInView(true);
  //         return inView
  //     } else {
  //         setInView(false);
  //         return false
  //     }
  // }
  // useEffect(() => {
  //     checkView()
  // })

  return (
    <section
      className={`project ${props.name}`}
    /* data-scroll-direction="horizontal"
          data-scroll-speed={1}
          data-scroll */
    >
      {props.children}
    </section>
  )
}

export default Project
