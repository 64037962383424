import React, { useEffect, useRef } from 'react'
import { extend, useFrame, useThree } from 'react-three-fiber'
import { useControl } from 'react-three-gui'
import { Vector2 } from 'three'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import Mosh from './gl/shaders/datamosh'

extend({
  EffectComposer,
  ShaderPass,
  RenderPass
})

const Effects = () => {
  const composer = useRef()
  const shaderPassRef = useRef()
  const prevMouse = useRef(new Vector2())
  const followMouse = useRef(new Vector2())
  const speed = useRef(0)
  const mouseRef = useRef(new Vector2())
  const targetSpeed = useRef(0)
  const amt = useControl('AMT', { type: 'number', min: -10, max: 10 })
  const { camera, gl, scene, size } = useThree()

  useEffect(() => {
    window !== undefined && window.addEventListener('pointermove', mouseMove)
  })

  useEffect(() => {
    composer.current.setSize(size.width, size.width)
  }, [size])

  useFrame(() => {
    getSpeed()
    mouseMove()
    shaderPassRef.current.uniforms.time.value += 0.01
    shaderPassRef.current.uniforms.uMouse.value = followMouse.current
    shaderPassRef.current.uniforms.uVelo.value = Math.min(targetSpeed.current, 0.1)
    targetSpeed.current *= 0.9999999999

    composer.current.render()
  }, 1)

  function getSpeed () {
    speed.current = Math.sqrt((prevMouse.current.x - mouseRef.current.x) ** 2 + (prevMouse.current.y - mouseRef.current.y) ** 2)

    targetSpeed.current -= 0.1 * (targetSpeed.current - speed.current)
    followMouse.current.x -= 0.1 * (followMouse.current.x - mouseRef.current.x)
    followMouse.current.y -= 0.1 * (followMouse.current.y - mouseRef.current.y)

    prevMouse.current.x = mouseRef.current.x
    prevMouse.current.y = mouseRef.current.y
  }

  function mouseMove (e) {
    // mousemove / touchmove

    if (window && mouseRef.current && typeof e !== 'undefined') {
      mouseRef.current.x = -1 + (e.clientX / window.innerWidth) * 2
      mouseRef.current.y = (e.clientY / window.innerHeight) * -2 + 1
    }
  }

  return (
    <>
      <effectComposer ref={composer} args={[gl]}>
        <renderPass attachArray="passes" scene={scene} camera={camera} />

        <shaderPass
          ref={shaderPassRef}
          args={[Mosh]}
          attachArray="passes"
          material-uniforms-pixels-value={[size.width / 8.0, size.height / 8.0]}
          material-uniforms-amt-value={amt}
          material-uniforms-resolution-value={[1.0, size.height / size.width]}
          // material-uniforms-tex1-value={fbo.current.texture}
          // material-uniforms-boundmode-value={2.0}
          // material-uniforms-tex1-value={fbo && fbo.current.texture}
          renderToScreen
        />

      </effectComposer>
    </>
  )
}

export default Effects
