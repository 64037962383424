import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios'

const Form = ({ question }) => {
  const [message, setMsg] = useState('')
  const synth2 = useRef()
  const [q, setQ] = useState(question)
  useEffect(() => {
    setQ(question)
    console.log('QUESTÃO DO FORM', question, q)
  }, [question])

  useEffect(() => {
    synth2.current = window && window.speechSynthesis
  }, [])

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  })
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    })
    if (ok) {
      form.reset()
    }
  }
  const clearPlaceholder = (e) => {
    e.target.setAttribute('placeholder', '')
  }

  const showPlaceholder = (e) => {
    e.target.getAttribute('value') !== '' && e.target.setAttribute('placeholder', 'Respond here')
  }

  const sayAnswer = async () => {
    if (window) {
      const voices = await synth2.current.getVoices()
      const utterance = new window.SpeechSynthesisUtterance(message)
      const localVoice = await voices.find(({ name }) => name === 'Samantha')
      utterance.lang = 'en-US'
      utterance.voice = localVoice
      synth2.current.speak(utterance)
    }
  }

  const handleOnSubmit = e => {
    e.preventDefault()
    sayAnswer()
    const form = e.target
    setServerState({ submitting: true })
    axios({
      method: 'post',
      url: 'https://formcarry.com/s/YJp5ebr6AE',
      data: new FormData(form)
    })
      .then(r => {
        handleServerResponse(true, 'Thanks!', form)
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form)
      })
  }
  const setMessage = event => {
    setMsg(event.target.value)
  }
  return (
    <div className='inputbox'>
      <form onSubmit={handleOnSubmit}>
        <input type="text" name="question" hidden onChange={setMessage} value={q} />
        <div className="form-control readonly-form-text">
          <div className="readonly-form-text-content">
            <textarea placeholder='Respond here' type='text' name="answer" rows='4' onChange={setMessage} autoComplete='off' onFocus={clearPlaceholder} onBlur={showPlaceholder} value={message} />
          </div>
        </div>
        <footer>
          <button type="submit" className='btn'>Submit</button>
          {serverState.status && (
            <p className={!serverState.status.ok ? 'errorMsg' : ''}>
              {serverState.status.ok ? 'Dataset uploaded' : 'Something went wrong, try again'}
            </p>
          )}
        </footer>
      </form>
      {/* <img src='caret.png' alt="" ref={caret}/> */}

    </div>
  )
}

export default Form
