import React, { useEffect, useLayoutEffect, useState } from 'react'
import { animated as a, config, useSpring, useTransition } from 'react-spring'
import useDeviceDetect from '../components/isMobile'
import useStore from '../utils/useStore.js'

const Navigation = () => {
  /**
   * SPRING SCROLL LOGIC
   */
  const storeSpring = useStore('storeSpring')
  const scrollValue = useStore('scrollValue')
  const isActive = useStore('isActive')
  const setIndex = useStore('setIndex')
  const setDotState = useStore('setDotState')
  const setStarted = useStore('setStarted')
  const [hash, setHash] = useState('')

  const [downIsOn, setDown] = useState(true)
  const [upIsOn, setUp] = useState(false)
  const loaded = useStore('loaded')
  const [ready, setReady] = useState(false)
  const waypointEnabled = useStore('waypointEnabled')
  const enableWaypoint = useStore('setWaypointEnabled')

  const { isMobileOnly, isMobile, isTablet } = useDeviceDetect()

  useEffect(() => {
    const tomb = document.getElementById('tomb-loc')
    scrollValue > 100 ? setUp(true) : setUp(false)
    const dest = isMobileOnly ? tomb.getBoundingClientRect().top + scrollValue : tomb.getBoundingClientRect().top + scrollValue - 140
    scrollValue && scrollValue > dest ? setDown(false) : setDown(true)
  }, [scrollValue])

  useEffect(() => {
    setHash(window.location.hash)
    const tomb = document.getElementById('tomb-loc')
    const remember = document.getElementById('remember-loc')
    const haunted = document.getElementById('the-haunt-loc')
    const ahuman = document.getElementById('a-human-loc')
    switch (hash) {
      case '#ahuman':
        moveToView(ahuman, isTablet ? 20 : 60)
        setStarted(true)

        break
      case '#recalling1993':
        moveToView(remember, isTablet ? 20 : 60)
        setStarted(true)

        break
      case '#thehaunt':
        moveToView(haunted, isTablet ? 20 : 60)
        setStarted(true)
        break
      case '#info':
        moveToView(tomb, isTablet ? 20 : 60)
        setStarted(true)
        break
    }
  }, [hash])

  useLayoutEffect(() => {
    const isReady = isMobile ? true : loaded
    setReady(isReady)
    return () => {
      setReady(false)
    }
  }, [isMobile, loaded])

  const [, setY] = useSpring(() => ({ y: 0 }))

  const transition = useTransition(ready, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.slow
  })

  function setVisible () {
    const body = document.body
    const html = document.documentElement
    const maxHeight = Math.max(body.scrollHeight, body.offsetHeight,
      html.clientHeight, html.scrollHeight, html.offsetHeight)
    const aboutHeight = document.getElementById('about').getBoundingClientRect().height
    // console.log('max', maxHeight)
    window.scrollY > 100 ? setUp(true) : setUp(false)
    // console.log('mobile scroll', window.scrollY, html.scrollHeight, body.scrollHeight, window.innerHeight)
    window.scrollY > (html.scrollHeight - aboutHeight - 800) ? setDown(false) : setDown(true)
  }

  function setNav (navItem) {
    const tomb = document.getElementById('tomb-loc')
    const remember = document.getElementById('remember-loc')
    const haunted = document.getElementById('the-haunt-loc')
    const ahuman = document.getElementById('a-human-loc')

    switch (navItem) {
      case ahuman:
        if (window) window.location.hash = 'ahuman'
        break
      case haunted:
        if (window) window.location.hash = 'thehaunt'
        break
      case remember:
        if (window) window.location.hash = 'recalling1993'
        break
      case tomb:
        if (window) window.location.hash = 'info'
        break
      case '':
        if (window) window.location.hash = ''
    }
  }

  useLayoutEffect(() => {
    if (isMobile) {
      window.addEventListener('scroll', setVisible)
      return () => {
        window.removeEventListener('scroll', setVisible)
      }
    }
  }, [isMobile])

  function moveToView (elm, offset = 0) {
    // console.log('moveToView -> elm', elm)
    if (!isMobile) {
      const dest = elm.getBoundingClientRect().top - offset + storeSpring.spring.scrollYValue.getValue()
      storeSpring.setScroll({
        scrollYValue: dest,
        onRest: e => {
          // console.log('on rest', this, e, waypointEnabled)
          setNav(elm)
        }
      })
    } else {
      setY({
        y: window.scrollY + elm.getBoundingClientRect().top - offset,
        reset: true,
        from: { y: window.scrollY },
        onFrame: props => window.scroll(0, props.y),
        onRest: (e) => {
          // console.log('on rest', this, e, enableWaypoint)
          setNav(elm)
        }
      })
    }
    setDotState(null)
    enableWaypoint(false)
    setTimeout(() => enableWaypoint(true), 1000)
    // console.log('WAYPOINTENABLED ON CLICK', waypointEnabled)
  }

  function goUp (e) {
    e.preventDefault()

    if (window) {
      const tomb = document.getElementById('tomb-loc')
      const remember = document.getElementById('remember-loc')
      const haunted = document.getElementById('the-haunt-loc')
      const ahuman = document.getElementById('a-human-loc')
      const currentPos = document.querySelector('.dot').getBoundingClientRect().top
      // setDotState(null)
      switch (true) {
        case currentPos >= tomb.getBoundingClientRect().top + 100:
          moveToView(remember, 60)
          // setDotState(null)
          break

        case currentPos >= remember.getBoundingClientRect().top + 60:
          moveToView(remember, 60)
          // setDotState(null)

          break

        case currentPos >= haunted.getBoundingClientRect().top + 60:
          moveToView(haunted, 60)
          // setDotState(null)

          break

        case currentPos >= ahuman.getBoundingClientRect().top + 60:
          moveToView(ahuman, 60)
          // setDotState(null)
          break

        default:
          setIndex('sleep')
          if (!isMobile) {
            storeSpring.setScroll({
              scrollYValue: 0,
              onRest: () => {
                setNav('')
              }
            })
          } else {
            setY({
              y: 0,
              reset: true,
              from: { y: window.scrollY },
              onFrame: props => window.scroll(0, props.y),
              onRest: () => {
                setNav('')
              }
            })
          }
          setDotState(null)
          break
      }
    }
  }

  function goDown (e) {
    e.preventDefault()
    // !isActive && setIndex('wakeup')

    if (window) {
      const tomb = document.getElementById('tomb-loc')
      const remember = document.getElementById('remember-loc')
      const haunted = document.getElementById('the-haunt-loc')
      const ahuman = document.getElementById('a-human-loc')

      const currentPos = document.querySelector('.dot').getBoundingClientRect().top
      // setDotState(null)

      switch (true) {
        case currentPos < ahuman.getBoundingClientRect().top - 60:
          moveToView(ahuman, 60)
          break

        case currentPos < haunted.getBoundingClientRect().top - 60:
          moveToView(haunted, 60)
          break

        case currentPos < remember.getBoundingClientRect().top - 60:
          moveToView(remember, 60)
          break

        case currentPos < tomb.getBoundingClientRect().top - 100:
          moveToView(tomb, 100)
          break
      }
    }
  }

  function goToInfo (e) {
    e.preventDefault()
    !isActive && setIndex('wakeup')

    if (window) {
      const el = document.querySelector('.tomb')
      if (isMobile) {
        const value = window.scrollY + el.getBoundingClientRect().top
        setY({
          y: value,
          reset: true,
          from: { y: window.scrollY },
          onFrame: props => window.scroll(document.getElementById('about').offsetTop, props.y),
          onRest: () => setNav('info')
        })
      } else {
        const dest = el.getBoundingClientRect().top + scrollValue - 100
        storeSpring.setScroll({
          scrollYValue: dest
        })
      }
    }
    // const value = window.scrollY + el.getBoundingClientRect().top
    // if (window) {
    //   setY({
    //     y: value,
    //     reset: true,
    //     from: { y: window.scrollY },
    //     onFrame: props => window.scroll(document.getElementById('about').offsetTop, props.y)
    //   })
    // }
  }

  return transition.map(({ item, key, props }) =>
    item && (
      <a.nav className="main-nav" key={key} style={props}>
        <ul>
          <li>
            <span className="btn">
              jen@jen.lu
            </span>
          </li>
          <li>
            <a href="#about" className={downIsOn ? 'btn' : 'btn inactive'} onClick={goToInfo} >
              Info
            </a>
          </li>

          <li className='arrow down'>
            <button className={downIsOn ? 'btn' : 'btn inactive'} onClick={goDown}><svg height="27" viewBox="0 0 9 27" width="9" xmlns="http://www.w3.org/2000/svg"><path d="m5 21.42v-21.42h-1v21.42l-4-2.35 4.49 7.93 4.51-7.93z" fill="#f00" /></svg></button>
          </li>

          <li className='arrow up'>
            <button className={upIsOn ? 'btn' : 'btn inactive'} onClick={goUp}><svg style={{ transform: 'rotate(180deg)' }} height="27" viewBox="0 0 9 27" width="9" xmlns="http://www.w3.org/2000/svg"><path d="m5 21.42v-21.42h-1v21.42l-4-2.35 4.49 7.93 4.51-7.93z" fill="#f00" /></svg></button>
          </li>
        </ul>
      </a.nav>
    )
  )
}

export default Navigation
