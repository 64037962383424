import clamp from 'lodash-es/clamp'
import React, { useEffect, useRef, useState } from 'react'
import { animated as a, useSpring } from 'react-spring'
import { useGesture } from 'react-use-gesture'
import useStore from '../utils/useStore'

export default () => {
  const scroll = useStore('scrollValue')
  const storeSpring = useStore('storeSpring').spring
  const setScroll = useStore('storeSpring').setScroll
  const height = useStore('documentHeight')
  const [dragging, setDragging] = useState(false)
  const size = useRef()
  const progress = useRef()
  const prevProgress = useRef(0)
  const prevDragStart = useRef(0)

  const [{ y }, set] = useSpring(() => ({ y: 0 }))

  useEffect(() => {
    var raf
    const cb = () => {
      if (storeSpring && !dragging) {
        const y = (window.innerHeight * storeSpring.scrollYValue.getValue()) / height

        if (y !== prevProgress.current) {
          prevProgress.current = y
          set({ y })
        }
        raf = requestAnimationFrame(cb)
      }
    }

    raf = requestAnimationFrame(cb)

    return () => {
      cancelAnimationFrame(raf)
    }
  }, [storeSpring, dragging, height, set])

  useEffect(() => {
    // if (storeSpring) console.log('Y', storeSpring.scrollYValue)
    size.current = typeof window !== 'undefined' ? (window.innerHeight / height * window.innerHeight) : null
    if (storeSpring) progress.current = typeof window !== 'undefined' && scroll / height * window.innerHeight
  }, [scroll, height, scroll])

  const bind = typeof window !== 'undefined' ? useGesture({
    onDrag: ({ xy: [, y], down, movement }) => {
      y = clamp(prevDragStart.current + movement[1], 0, window.innerHeight - size.current)
      set({ y, immediate: down })

      const newY = window && ((y * height)) / window.innerHeight
      setScroll({ scrollYValue: newY })
    },
    onDragStart: () => {
      setDragging(true)
      prevDragStart.current = y.getValue()
    },
    onDragEnd: () => setDragging(false)
  },
  {
    eventOptions: {
      pointer: true
    }
  }
  ) : () => {}

  useEffect(() => {
    bind()
  }, [bind])

  return (
    <div className='scrollbar-container' onMouseDown={e => e.stopPropagation()}>
      <a.div className={`scroll-progress ${dragging ? 'dragging' : null}`}
        style={{
          transform: y.interpolate(y => `translate3d(0,${y}px,0)`),
          // top: y,
          height: `${size}px`
        }}
        {...bind()}>
      </a.div >
    </div>
  )
}
