import PropTypes from 'prop-types'
import React, { useLayoutEffect, useState } from 'react'
import { animated as a, config, useTransition } from 'react-spring'

const Header = ({ siteTitle, className }) => {
  const [mounted, setMount] = useState(false)

  useLayoutEffect(() => {
    setMount(true)
    return () => {
      setMount(false)
    }
  }, [])

  const transition = useTransition(mounted, null, {
    from: { transform: 'translateY(-50px)', opacity: 0 },
    enter: { transform: 'translateY(0)', opacity: 1 },
    leave: { transform: 'translateY(-50px)', opacity: 0 },
    config: config.slow
  })

  return (
    <header className={className}>
      { transition.map(({ item, key, props }) =>
        item && (
          <a.h1 key={key} style={props}>
          JEN LU <br />
          EXPERIENTIAL <br />
          ARTIST
          </a.h1>
        )
      )}
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ''
}

export default Header
