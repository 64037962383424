
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'

const Image = props => {
  const data = useStaticQuery(graphql`
    query {
      mobileImages: allFile(filter:{name:{regex: "/_m$/"}}) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 768) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      desktopImages: allFile(filter:{name:{regex: "/^(?!.*(_m)$)/"}}) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const image = data.desktopImages.edges.find(n => {
    return n.node.name.includes(props.fileName)
  })
  const imageM = data.mobileImages.edges.find(n => {
    return n.node.name.includes(props.fileNameMobile)
  })

  if (!image) {
    return null
  }
  let fluid
  if (imageM) {
    fluid = [
      { ...imageM.node.childImageSharp.fluid, media: '(max-width: 767px)' },
      { ...image.node.childImageSharp.fluid, media: '(min-width: 768px)' }
    ]
  } else { fluid = image.node.childImageSharp.fluid }

  return (
    <div
      // data-scroll-direction="horizontal"
      style={{
        ...props.style,
        overflow: 'hidden'
      }}
      className={props.className}
      id={props.id || ''}
    >
      <Img
        alt={props.alt}
        backgroundColor={props.color}
        durationFadeIn={700}
        loading={props.loading}
        fluid={fluid}
      />
    </div>
  )
}

Image.propTypes = {
  alt: PropTypes.string,
  color: PropTypes.string,
  duration: PropTypes.number,
  loading: PropTypes.string,
  fixed: PropTypes.string
}

Image.defaultProps = {
  color: 'rgba(0,0,0,0)',
  duration: 700,
  loading: 'lazy'
}

export default Image
