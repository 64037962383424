import React, { useEffect, useRef, useState } from 'react'
import { animated as a, config, useSpring } from 'react-spring'
import useMeasure from '../utils/useMeasure'
import debounce from 'lodash-es/debounce'

const Credits = ({ name, children, className }) => {
  const [open, setOpen] = useState(false)
  const [bind, { height }] = useMeasure()
  const [marginTop, setMargin] = useState(null)
  const accordion = useSpring({
    height: !open ? 0 : height + marginTop,
    overflow: 'hidden',
    config: config.default,
    onRest: () => {
      window && window.dispatchEvent(new Event('resize'))
    }
  })
  const ref = useRef()

  useEffect(() => {
    setMargin(parseInt(window.getComputedStyle(ref.current.querySelector('article')).marginTop))
  }, [])

  return (
    <section className='credits'>
      <button onClick={() => setOpen(!open)} className={open ? 'pressed' : undefined}><span>{open ? 'Hide' : 'View'}</span> {name} credits
        <svg height="27" viewBox="0 0 9 27" width="9" xmlns="http://www.w3.org/2000/svg"><path d="m5 21.42v-21.42h-1v21.42l-4-2.35 4.49 7.93 4.51-7.93z" fill="#f00" /></svg>
      </button>
      <a.section style={accordion} ref={ref}>
        <article {...bind} className={className}>
          {children}
        </article>
      </a.section>
    </section>
  )
}

Credits.defaultProps = {
  className: 'col-3'
}

export default Credits
