import React, { useEffect, useLayoutEffect, useState } from 'react'

// export const PlaylistContext = React.createContext()

export default function Audio ({ file }) {
  let audioPlayer
  const [audio, setAudio] = useState(file)

  const handlePlay = () => {
    // handleStop()
    // audioPlayer.current.attributes.source.src = file
    audioPlayer.currentTime = 0
    audioPlayer.oncanplay = () => audioPlayer.play()
  }

  useLayoutEffect(() => {
    audioPlayer = document.getElementById('audioPlayer')
  })

  useEffect(() => {
    setAudio(file)
    handlePlay()
  },
  [file]
  )

  return (
    <audio id="audioPlayer" src={audio} >
      {/* <source src={audio} type="audio/mp3" /> */}
        Ooops, your browser is sooo old.
    </audio>
  )
}
