import moment from 'moment'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { animated as a, useSpring, useTransition } from 'react-spring'
import Audio from '../components/audio'
import Image from '../components/image'
import useDeviceDetect from '../components/isMobile'
import PHRASES from '../data/data'
// import ErrorBoundary from '../utils/errorBoundary'
import useStore from '../utils/useStore.js'

export default function DotContainer () {
  const index = useStore('index')
  const isActive = useStore('isActive')
  const setActive = useStore('setActive')
  const dotState = useStore('dotState')
  const setDotState = useStore('setDotState')
  const [message, setMessage] = useState(`TURN SPEAKERS ON,
 AND CLICK
 TO ENABLE AGENT`)
  const [audio, setAudio] = useState('')
  const [width, setWidth] = useState(0)
  const [numIdles, setNumIdles] = useState(0)

  const started = useStore('started')
  const setStarted = useStore('setStarted')
  const setIntro = useStore('setIntro')
  const introComplete = useStore('introComplete')
  const [firstClick, setFirstClick] = useState(false)
  const waypointEnabled = useStore('waypointEnabled')

  const [voice, setVoice] = useState(null)
  const [progress, setProgress] = useState('')
  const [temperature, setTemperature] = useState(18)

  const audioRef = useRef()
  const timer = useRef(null)
  const idleTimer = useRef()
  const synthRef = useRef()
  const speakTimer = useRef(null)
  const animTimer = useRef(null)
  const sleepTimer = useRef(null)
  const progressShowedOnce = useRef(false)

  const scrollValue = useStore('scrollValue')
  const documentHeight = useStore('documentHeight')

  const { isMobile, isMobileOnly } = useDeviceDetect()

  //! debounce para não ter fazer tantas calls em scroll
  //! FIXME: se usado, atualizar calls ao `index` para `index`
  // const [index] = useDebounce(index, 1000)

  //= ============= PRELOAD SYNTH VOICES + AUDIO FILES =================

  function preloadVoices () {
    synthRef.current = window && window.speechSynthesis
    if (synthRef.current.onvoiceschanged !== undefined) {
      synthRef.current.onvoiceschanged = () => {
        const voices = synthRef.current.getVoices()
        const localVoice = voices.find(({ name }) => name === 'Samantha')
        setVoice(localVoice)
      }
    }
  }

  async function preloadTracks () {
    const tracksPhrases = []
    PHRASES.map((phrase) => {
      return 'file' in phrase && tracksPhrases.push(phrase.file)
    })
    tracksPhrases.map(async track => fetch(track))
  }

  function fetchWeather () {
    fetch('https://ipapi.co/json/', { mode: 'cors' })
      .then(response => response.json())
      .then(data => {
        fetch(`https://api.openweathermap.org/data/2.5/weather?appid=b8f4fc0236b5fe4ac149da05a576e729
&lat=${data.latitude}&lon=${data.longitude}&units=metric`)
          .then(r => r.json())
          .then(data => setTemperature(Math.floor(data.main.temp)))
      })
  }

  function setPace () {
    // const pace = typeof index === 'number' && index > 0 && audioRef.current.duration ? audioRef.current.duration / (message.length * 1.2) : message && message.length / 20
    if (index === 'intro' || index === 'outro') return
    const pace = PHRASES[index].pace
    document.documentElement.style.setProperty('--pace', `${pace}ms`)
  }

  //= ============= SPRING ANIMATIONS =================

  const animWidth = useSpring({
    width: dotState === null ? (isMobileOnly ? 67 : '6em') : (isMobileOnly ? width * 0.7 : `${width / 16}em`),
    config: {
      mass: 1,
      tension: 500,
      friction: 50
    }
  })

  const opacityAnim = useSpring({
    opacity: dotState === null ? 0 : 1,
    config: { duration: 0.5 }
  })
  const slowOpacity = useSpring({
    opacity: dotState === 'idle' ? 1 : 0,
    config: { duration: 2 }
  })

  const transition = useTransition(dotState, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 200 }
  })

  // useDebounce(dotState, 250)
  //= ============= SCROLL PROGRESS EFFECT =================

  //! ============= FUNÇÕES DE FALA ========================
  //  ————————————————  início  ————————————————————————————

  function playIntro () {
    setStarted(true)
    setIntro(true)
    isMobile ? setWidth(200) : setWidth(260)
    setDotState('thinking')
    speakTimer.current = setTimeout(() => {
      inOutDot('intro')
    }, 1500)
  }

  function cancelIdle () {
    clearTimeout(idleTimer.current)
    // console.log('cancel idle', progress)
    if (introComplete && dotState === null && !progressShowedOnce.current && parseInt(progress) > 5) {
      idleTimer.current = setTimeout(() => {
        progressShowedOnce.current = true
        setDotState('idle')
        setWidth(400)
        dotState === null && reportProgress()
      }, 5000)
    } else {
      progressShowedOnce.current = false
    }
  }

  useEffect(() => {
    const scrollPx = scrollValue
    const winHeightPx = documentHeight - document.documentElement.clientHeight
    const scrolled = `${Math.floor(scrollPx / winHeightPx * 100)}%`
    setProgress(scrolled)
    // console.log('SCROLLED', scrollPx, scrolled)
    cancelIdle()
  }, [scrollValue, documentHeight])

  function scrollProgress () {
    const scrollPx = document.documentElement.scrollTop
    const winHeightPx = document.documentElement.scrollHeight - document.documentElement.clientHeight
    const scrolled = `${Math.floor(scrollPx / winHeightPx * 100)}%`
    setProgress(scrolled)
    cancelIdle()
  }

  function reportProgress () {
    const idlesnr = numIdles + 1
    setNumIdles(idlesnr)
    if (numIdles < 4 && dotState === null) {
      clearTimeout(idleTimer.current)
      setWidth(260)
      setDotState('thinking')
      setTimeout(() => {
        setWidth(400)
        setDotState('idle')
        // clearTimeout(timer.current)
        timer.current = setTimeout(() => {
          setDotState(null)
        }, 4000)
      }, 1000)
    }
  }

  function inOutDot (stage, rollover, dotSleep) {
    audioRef.current.onended = null
    let txt
    if (stage === 'intro') {
      const inLines = [
        {
          phrase: `Hello and welcome,
 I'm Dot.`,
          file: '/speeches/1.mp3'
        },
        {
          phrase: `Please allow me
 to make your
 experience better.`,
          file: '/speeches/2.mp3'
        }
      ]

      setDotState('instructions')
      setWidth(440)
      txt = inLines[0].phrase.split(' ').map((word, i, tot) => <span key={i + Math.random()}>{word}{i < tot - 1 ? '' : ' '}</span>)
      // console.log('dotState: ' + dotState)
      setMessage(txt)
      isActive && setAudio(inLines[0].file)

      speakTimer.current = setTimeout(() => {
        setWidth(400)
        txt = inLines[1].phrase.split(' ').map((word, i, tot) => <span key={i + Math.random()}>{word}{i < tot - 1 ? '' : ' '}</span>)
        setMessage(txt)
        isActive && setAudio(inLines[1].file)
        setTimeout(() => {
          setDotState(null)
          if (rollover && dotSleep) setStarted(false)
        }, 4000)
      }, 2000)
    } else {
      const outLines = [
        {
          phrase: `Achievement unlocked.
 Website completed.`,
          file: '/speeches/22.mp3'
        },
        {
          phrase: `© 2020
 Version 5.0`,
          file: '/speeches/23.mp3',
          title: true
        }
      ]

      setWidth(420)
      txt = outLines[0].phrase.split(' ').map((word, i, tot) => <span key={i + Math.random()}>{word}{i < tot - 1 ? '' : ' '}</span>)
      setMessage(txt)
      setDotState('outro')
      isActive && setAudio(outLines[0].file)
      document.documentElement.style.setProperty('--pace', '400ms')
      speakTimer.current = setTimeout(() => {
        setDotState('thinking')
        setWidth(260)
        setTimeout(() => {
          setWidth(300)
          setDotState('outro')
          txt = outLines[1].phrase.split(' ').map((word, i, tot) => <span key={i + Math.random()}>{word}{i < tot - 1 ? '' : ' '}</span>)
          setMessage(txt)
          isActive && setAudio(outLines[1].file)
          setTimeout(() => {
            setDotState(null)
          }, 4000)
        }, 1000)
      }, 2500)
    }
  }

  function think () {
    if (!waypointEnabled) return
    if (index === 'none' || index == null) return
    // started && setStarted(true)
    setAudio('')
    window && synthRef.current.cancel()
    isMobileOnly ? setWidth(180) : setWidth(260)
    setDotState('thinking')

    if (index === 'intro') {
      if (!introComplete) {
        if (!isMobile) {
          const line = `TURN ON SPEAKERS,
  AND ${isMobile ? 'TAP' : 'CLICK'} TO
  ENABLE AGENT`
          setWidth(400)
          setDotState('intro')
          setMessage([<span key="wakeupMsg">{line}</span>])

          return setTimeout(() => {
            setIntro(true)
            setWidth(260)
            setDotState('thinking')
            setTimeout(() => {
              inOutDot('intro')
            }, 1500)
          }, 2500)
        } else {
          setTimeout(() => {
            setIntro(true)
            setWidth(260)
            setDotState('thinking')
            setTimeout(() => {
              inOutDot('intro')
            }, 1500)
          }, 500)
        }
      } else {
        return setTimeout(setDotState(null), 1500)
      }
    }

    if (index === 'outro') {
      return setTimeout(() => {
        inOutDot('outro')
      }, 1500)
    }
    setIntro(true)
    return setTimeout(say, 1500)
  }

  function say () {
    if (typeof index === 'number' && PHRASES[index].hasOwnProperty('phrase')) {
      (isMobile && PHRASES[index].hasOwnProperty('mobileWidth')) ? setWidth(PHRASES[index].mobileWidth) : setWidth(PHRASES[index].width)
      const msg = PHRASES[index].phrase.split(' ').map((word, i, tot) => <span key={i + Math.random()}>{word}{i < tot - 1 ? '' : ' '}</span>)
      setMessage(msg)
      setDotState('talking')
      isActive && setAudio(PHRASES[index].file)
      setPace()
    } else if (index === 'weather') {
      isActive && setAudio('')
      const feel = () => {
        if (temperature < 13) {
          setWidth(380)
          return 'Stay warm.'
        } else if (temperature > 21) {
          setWidth(380)
          return 'Stay cool.'
        } else {
          setWidth(300)
          return ''
        }
      }
      const realFeel = feel()
      const txt = `It's ${temperature}°C. ${realFeel}`.split(' ').map((word, i, tot) => <span key={i + Math.random()}>{word}{i < tot - 1 ? '' : ' '}</span>)
      setMessage(txt)
      setDotState('talking')

      isActive && speak(`It's ${temperature} degrees celsius. ${realFeel}`)
      // isActive && setAudio(null)
    } else if (index === 'date') {
      setWidth(420)
      const virginDate = moment()
      const readDate = virginDate.format('D. M. YYYY')
      const sayDate = virginDate.format('Do of MMMM YYYY')
      // const date = virginDate.toTimeString()
      isActive && setAudio('')
      const txt = `Today is ${readDate}, and
 you're getting closer
 to your goal.`
      const switchedTxt = txt.split(' ').map((word, i, tot) => <span key={i + Math.random()}>{word}{i < tot - 1 ? '' : ' '}</span>)
      setMessage(switchedTxt)
      setDotState('talking')

      isActive && speak(`Today is ${sayDate}, and you're getting closer to your goal.`)
      // isActive && setAudio(null)
    }
  }

  async function speak (sentence) {
    if (window) {
      const utterance = new window.SpeechSynthesisUtterance(sentence)
      utterance.lang = 'en-US'
      utterance.voice = voice
      synthRef.current.speak(utterance)
      utterance.addEventListener('end', () => {
        setDotState(null)
        setWidth(0)
        isMobile && scrollProgress()
      })
    }
  }

  //! ============= FUNÇÕES DE FALA ========================
  //  ——————————————————  fim  —————————————————————————————

  // ============= MAIN EFFECT FOR PRELOADS & WEATHER FETCH =================

  useEffect(() => {
    preloadTracks()
    preloadVoices()
    fetchWeather()
  }, [])

  //! Igual a useEffect mas tem delay pra esperar o layout acabar de pintar para ler coordenadas
  // SCROLL POSITION EFFECT
  useLayoutEffect(() => {
    isMobile && window && window.addEventListener('scroll', scrollProgress, { passive: true })
    // window && window.addEventListener('click', cancelIdle)
    // isMobile && window && window.addEventListener('mousemove', scrollProgress, { passive: true })

    //! return é a função que equivale ao ComponentWillUnmount
    return () => {
      isMobile && window && window.removeEventListener('scroll', scrollProgress)
      // isMobile && window && window.removeEventListener('mousemove', scrollProgress)
      // window.removeEventListener('click', cancelIdle)
      clearTimeout(idleTimer.current)
    }
  }, [isMobile])

  useLayoutEffect(() => {
    isMobile && window && window.addEventListener('scroll', scrollProgress)

    return () => {
      clearTimeout(idleTimer.current)
      isMobile && window && window.removeEventListener('scroll', scrollProgress)
    }
  }, [introComplete, dotState, isMobile])

  function expand (e) {
    if (introComplete && dotState === null) {
      clearTimeout(speakTimer.current)
      if ((index === 'intro' || index === 'sleep') && introComplete) {
        const dotSleep = !(started || index !== 'sleep')
        setStarted(true)
        setIntro(true)
        setWidth(260)
        setDotState('thinking')
        speakTimer.current = setTimeout(() => {
          inOutDot('intro', true, dotSleep)
        }, 1500)
      } else if (index === 'wakeup') return
      else speakTimer.current = think()
    }
  }

  function enableTalking () {
    if (!firstClick) {
      audioRef.current.src = '/speeches/silence.mp3'
      audioRef.current.play()
      setFirstClick(true)
    }
    setActive(!isActive)
    isActive ? audioRef.current.volume = 0 : audioRef.current.volume = 1
  }

  //! ===================== MAIN EFFECT ON INDEX CHANGE  ==========================
  //! ============= RUNS ALL THE IMPORTANT SCRIPTS AND SETS TEXTS =================
  function handleAnimationEnded () {
    clearTimeout(animTimer.current)
    animTimer.current = setTimeout(() => {
      setDotState(null)
      isMobile && scrollProgress()
    }, 1500)
  }
  useEffect(() => {
    audioRef.current = document.getElementById('audioPlayer')

    if (index !== 'outro' && index !== 'wakeup' && index !== 'sleep' && index !== null || (index === 'intro' && !introComplete)) {
      isActive ? audioRef.current.onended = () => {
        setTimeout(() => {
          setDotState(null)
          scrollProgress()
        }, 1500)
      }
        : document.querySelector('.dot').addEventListener('animationend', handleAnimationEnded)
    }

    switch (index) {
      case 'wakeup':
        !started && setStarted(true)
        break
      case 'sleep':
        setDotState(null)
        setMessage('')
        setWidth(0)
        setAudio('')
        window && synthRef.current.cancel()
        started && setStarted(false)
        break
      default:
        if (index === 'intro' && introComplete) return
        speakTimer.current = think()
        break
    }

    if (typeof index === 'number' && PHRASES[index].hasOwnProperty('phrase')) setPace(index)

    return () => {
      clearTimeout(speakTimer.current)
      clearTimeout(animTimer.current)

      document.querySelector('.dot').removeEventListener('animationend', handleAnimationEnded)
    }
    //! return é a função que equivale ao componentWillUnmount
  }, [index]) //! a array diz que dependências fazem correr o Efeito, quando fazem UPDATE

  useEffect(() => {
    if (index === 'intro') {
      setAudio('')
      window && synthRef.current.cancel()
      clearTimeout(speakTimer.current)
      clearTimeout(animTimer.current)
      playIntro()
    } else if (introComplete && isActive) { speakTimer.current = think() }

    return () => {
      /* clearTimeout(speakTimer.current)
      clearTimeout(animTimer.current)
      document.querySelector('.dot').removeEventListener('animationend', handleAnimationEnded) */
    }
  }, [isActive])

  // utterance.rate = 0.5
  // utterance.pitch = 0.6
  // utterance.voice = window.speechSynthesis.getVoices()[11]

  return (
    <>
      <a.div
        className={`dot ${dotState === null ? '' : 'on'} ${started ? 'started' : undefined}`}
        onClick={!isMobile && enableTalking}
        onPointerEnter={expand}
        onPointerLeave={() => {
          if (dotState === 'hover') {
            setDotState(null)
          }
        }}
        style={{
          ...animWidth
        }}
      >
        <div className="bot">
          <Image fileName='dot_1' style={{
            position: 'absolute',
            top: 0,
            left: 0
          }} loading='eager' />
          <Image fileName='dot_2' loading='eager' className={isActive ? 'active' : undefined} />
        </div>
        {dotState === 'idle'
          ? <a.small style={slowOpacity} className='idle'>
            <p>{progress} PROGRESS</p>
            <div className='progress'>
              <div style={{ width: progress }}>
              </div>
            </div>
          </a.small>
          : dotState === 'thinking'
            ? <>
              {transition.map(({ item, key, props }) =>
                item && (
                  <a.div className='loader' key={key} style={props}>
                    <i></i><i></i><i></i>
                  </a.div>
                )
              )}
            </>
            : <>
              {
                transition.map(({ item, key, props }) =>
                  item && (
                    <a.small key={key} style={{ ...props, ...opacityAnim }} className={dotState !== null ? '' : 'toggle'}>{message}</a.small>
                  )
                )
              }
              <a.svg width="84px" height="74px" viewBox="0 0 84 74" version="1.1" xmlns="http://www.w3.org/2000/svg" style={opacityAnim}>
                <title>sound</title>
                <g id="Group" transform="translate(0.377900, 0.000000)">
                  {
                    isActive
                      ? <>
                        <g id="on" transform="translate(67.622100, 37.572300) scale(-1, 1) translate(-67.622100, -37.572300) translate(51.622100, 5.572300)" fill="#FF0000">
                          <path d="M22.4863,31.9141 C22.4863,37.1791 25.7683,41.8201 30.6873,43.6001 L31.8953,40.8521 C28.0583,39.5541 25.4863,35.9761 25.4863,31.9141 C25.4863,27.9451 27.9303,24.4581 31.6113,23.0831 L30.5233,20.2891 C25.6913,22.1111 22.4863,26.6981 22.4863,31.9141" id="Fill-2" />
                          <path d="M14.2432,31.9258 C14.2432,23.3628 19.6562,15.8608 27.7382,13.1398 L26.6502,10.3458 C17.4172,13.5158 11.2432,22.1148 11.2432,31.9258 C11.2432,41.6178 17.3042,50.1628 26.3792,53.4078 L27.5862,50.6588 C19.5942,47.8968 14.2432,40.4158 14.2432,31.9258" id="Fill-4" />
                          <path d="M3,31.937 C3,18.78 11.38,7.257 23.865,3.197 L22.777,0.403 C9.142,4.914 0,17.532 0,31.937 C0,46.06 8.838,58.505 22.068,63.217 L23.276,60.468 C11.129,56.238 3,44.856 3,31.937" id="Fill-6" />
                        </g>
                      </>
                      : <>
                        <g id="mute" transform="translate(66.000000, 37.644600) scale(-1, 1) translate(-66.000000, -37.644600) translate(51.000000, 22.144600)" stroke="#FF0000" strokeWidth="3">
                          <line x1="29.6479" y1="0.4199" x2="0.0609" y2="30.0079" id="Stroke-1" />
                          <line x1="29.6479" y1="30.0078" x2="0.0609" y2="0.4198" id="Stroke-3" />
                        </g>
                      </>
                  }
                  <polygon id="speaker" fill="#FF0000" transform="translate(18.834500, 36.786000) scale(-1, 1) translate(-18.834500, -36.786000) " points="4.61852778e-14 0 4.61852778e-14 73.572 26.704 45.41 37.669 45.41 37.669 27.41 26.474 27.41" />
                </g>
              </a.svg>
            </>
        }
      </a.div>
      <Audio file={audio} />
    </>
  )
}
